import React, { PropsWithChildren, useState, useMemo } from "react";
import { Each } from "../../utils/Each";

interface Props {
  leadLists: Array<any>;
  onAddList: (mode: string) => void;
  onEditList: (mode: string, _id: string) => void;
  getLeadList: () => void;
  onDeleteClick: (id: string) => void;
  leadData: number;
  LeadNameChange: (_id: string, name: string) => void;
  selectedLeadList: any;
  isLeadSelection?: boolean;
}

const LeadsList: React.FC<PropsWithChildren<Props>> = (props) => {
  const {
    leadLists,
    onAddList,
    getLeadList,
    onDeleteClick,
    onEditList,
    leadData,
    LeadNameChange,
    selectedLeadList,
    isLeadSelection = false,
  } = props;

  const [active, setActive] = useState<string>("Leads");
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const sortedLeadLists = useMemo(() => {
    return [...leadLists].sort((a, b) => a.name.localeCompare(b.name));
  }, [leadLists]);

  return (
    <div
      className="dropdown ms-1"
      style={{
        backgroundColor: "#EBF0F4",
        borderRadius: "6px",
      }}
    >
      <button
        className="btn dropdown-toggle text-black fw-bold lead_icon"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        onClick={getLeadList}
      >
        {selectedLeadList.name} ({leadData})
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-play-circle-fill"
          viewBox="0 0 16 16"
        >
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
        </svg>
        <div className="triangle"></div>
      </button>

      <ul
        className="leads_dropdown lead_list dropdown-menu pt-0 pb-0"
        aria-labelledby="dropdownMenuButton1"
        id="lists-dropdown"
        style={{
          overflow: "auto",
          maxHeight: "77vh",
          background: "white",
          WebkitFontSmoothing: "antialiased",
          MozOsxFontSmoothing: "grayscale",
        }}
      >
        {!isLeadSelection && (
          <li className="d-flex justify-content-between align-items-center mt-2">
            <span className="ms-3">Lead list</span>
            <button
              className="btn btn-primary me-2"
              onClick={() => onAddList("add")}
            >
              Add new list
            </button>
          </li>
        )}

        <li
          className={`lead dropdown-item cursor-pointer d-flex justify-content-between align-items-center dropdown-list-item ${
            active === "Leads" && "active"
          }`}
          onClick={() => {
            LeadNameChange("0", "Leads");
            setActive("Leads");
          }}
        >
          Default leadlist
        </li>
        <Each
          of={sortedLeadLists}
          render={(listData, index) => {
            return (
              <li
                className={`lead dropdown-item cursor-pointer d-flex justify-content-between align-items-center dropdown-list-item ${
                  (active == listData?.name || hoveredIndex === index) &&
                  "active"
                }`}
                onClick={() => {
                  LeadNameChange(listData?._id, listData?.name);
                  setActive(listData?.name);
                }}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                {`${listData?.name} (${listData?.leadCount ?? 0})`}

                {!isLeadSelection &&
                  (active == listData?.name || hoveredIndex === index) && (
                    <div className="fliter_btn">
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => {
                          onEditList("edit", listData?._id);
                        }}
                      >
                        <i>
                          <svg
                            width="14"
                            height="11"
                            viewBox="0 0 14 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.60103 3.66667L9.33204 4.24111L2.2708 9.77778H1.55534V9.21556L8.60103 3.66667ZM11.4006 0C11.2062 0 11.004 0.0611111 10.8563 0.177222L9.43314 1.29556L12.3494 3.58722L13.7725 2.46889C14.0758 2.23056 14.0758 1.83333 13.7725 1.60722L11.9528 0.177222C11.7973 0.055 11.6028 0 11.4006 0ZM8.60103 1.94944L0 8.70833V11H2.91626L11.5173 4.24111L8.60103 1.94944Z"
                              fill="#3FAEFD"
                              fillOpacity="0.82"
                            />
                          </svg>
                        </i>
                      </button>
                    </div>
                  )}
              </li>
            );
          }}
        />
      </ul>
    </div>
  );
};

export default LeadsList;
