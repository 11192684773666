import { Formik } from "formik";
import {
  clearInputValueById,
  removeEmptyValues,
} from "../../hooks/use-debounce";
import InboxList from "./inboxList";
import { Button } from "antd";
import PhoneInput from "react-phone-input-2";
import { useState } from "react";
import { Error } from "./style";

const ChatLeftMenu = ({
  usersArray,
  handleFilter,
  filter,
  setFilter,
  handleTagFilter,
  addChat,
  addChatHandler,
  setAddChat,
  handleConnect,
  selectedUser,
  onScrollListing,
  listInnerRef,
}: any) => {
  const [value, setValue] = useState("");

  return (
    <div className="userLists pe-1">
      <div className="chat-list-wrapper">
        {/* Search & Filter Card */}
        <div className="search-filter-card">
          {/* Search Input */}
          <div className="search-wrapper">
            <i className="fa-sharp fa-magnifying-glass search-icon"></i>
            <input
              className="search-input"
              placeholder="Type to search..."
              name="search"
              id="searchInput"
              onChange={handleFilter}
              type="text"
            />
            {filter.search && (
              <button
                className="clear-btn"
                onClick={() => {
                  setFilter(removeEmptyValues({ ...filter, search: "" }));
                  clearInputValueById("searchInput");
                }}
              >
                <i className="fa-regular fa-times"></i>
              </button>
            )}
          </div>
  
          {/* Filter Tags Row */}
          <div className="filter-tags-row">
            <button
              className={`filter-tag ${filter.isRead ? "active" : ""}`}
              onClick={() => handleTagFilter("isRead")}
            >
              <i className="fa-regular fa-envelope"></i>
              Unread
              {filter.isRead && <i className="fa-regular fa-xmark"></i>}
            </button>
  
            <button
              className={`filter-tag ${filter.isAssigned ? "active" : ""}`}
              onClick={() => handleTagFilter("isAssigned")}
            >
              <i className="fa-regular fa-user"></i>
              Unassigned
              {filter.isAssigned && <i className="fa-regular fa-xmark"></i>}
            </button>
  
            <button
              className={`filter-tag ${filter.isAssignedToMe ? "active" : ""}`}
              onClick={() => handleTagFilter("isAssignedToMe")}
            >
              <i className="fa-regular fa-user-check"></i>
              Assigned to me
              {filter.isAssignedToMe && <i className="fa-regular fa-xmark"></i>}
            </button>
  
            <button className="add-filter-btn">
              <i className="fa-solid fa-plus"></i>
            </button>
          </div>
        </div>
  
        {/* Chat List */}
        <div className="flex-fill position-relative">
          <div className="userListItems">
            {addChat && (
              <div className="item mb-2 bg-white rounded-8 p-1">
                <Formik
                  initialValues={{ mobileNumber: "" }}
                  validate={(values) => {
                    const errors: any = {};
                    if (!values.mobileNumber) {
                      errors.mobileNumber = "Mobile number is required";
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting, setErrors }) => {
                    addChatHandler(
                      values,
                      () => setSubmitting(false),
                      () => {
                        setSubmitting(false);
                        setErrors({ mobileNumber: "Something went wrong" });
                      }
                    );
                  }}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form
                      className="d-flex justify-content-between w-100"
                      onSubmit={handleSubmit}
                    >
                      <div className="w-100">
                        <PhoneInput
                          value={value}
                          inputProps={{
                            name: "mobileNumber",
                          }}
                          inputClass="form-control w-100 border-0"
                          country="in"
                          masks={{
                            fr: "(...) ..-..-..",
                            at: "(....) ...-....",
                          }}
                          onChange={(val, country, e) => handleChange(e)}
                          preferredCountries={["in"]}
                          buttonClass="bg-transparent border-0 rounded-8"
                        />
                        {errors.mobileNumber && (
                          <Error>{errors.mobileNumber}</Error>
                        )}
                      </div>
                      <div className="d-flex gap-2 ps-4">
                        <Button
                          onClick={() => setAddChat(false)}
                          className="border danger-btn"
                          icon={<i className="fa-regular fa-times"></i>}
                        />
                        <Button
                          loading={isSubmitting}
                          htmlType="submit"
                          className="border success-btn"
                          icon={<i className="fa-regular fa-check"></i>}
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            )}
  
            {usersArray.length ? (
              <ul
                className="chat_list_container"
                ref={listInnerRef}
                onScroll={onScrollListing}
              >
                <InboxList
                  users={usersArray}
                  setSelectedUser={handleConnect}
                  selectedUser={selectedUser}
                />
              </ul>
            ) : (
              <div className="h-100 d-flex align-items-center justify-content-center">
                <h5 className="text-dark fm-gilroy fs-6 text-center">
                  No results found
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChatLeftMenu;