import { useCallback, useEffect, useState } from "react";
import DrawerComponent from "../../components/drawer";
import LeadDetails from "../leads/leadDetails";
import { useDispatch } from "react-redux";
import { getLeadDetail } from "../../services/leadService";
import {
  leadFileList,
  setActivityList,
  setLeadTaskList,
  setNote,
} from "../../actions/actions";
import { useSelector } from "react-redux";
import moment from "moment";
import FormResponse from "./FormResponse";
import { getCustomFormResponse } from "../../services/formService";
import toast, { Toaster } from "react-hot-toast";
import styled from "styled-components";
import { uploadFiles } from "../../services/utilityService";
import CreateLeadDetailsTaskForm from "../../components/createLeadTaskForm";
import SelectLeadStatus from "../../components/status";
import SelectLeadLabel from "../../components/label";
type propType = {
  tableData: any;
};
export interface LeadDataI {
  _id: string;
  name: string;
  integration: string;
  customSource: string;
  createdAt: Date;
  status: Array<string>;
  saleValue: string;
  website: string;
  phone: string;
  email: string;
  label: Array<any>;
}

const FormTable: React.FC<propType> = ({ tableData }) => {
  const [leadData, setLeadData] = useState([]);
  const [leadToBeUpdate, setLeadToBeUpdate] = useState<string>("");
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [toBeRemoved, setToBeRemoved] = useState<string>("");
  const [from, setFrom] = useState<string>("");
  const [leadId, setLeadId] = useState<any>();
  const [moveLeadToListModal, setShowMoveLeadToList] = useState<boolean>(false);
  const [openWithHeader, setOpenWithHeader] = useState<boolean>(false);
  const [leadLabelData, setLeadLabelData] = useState<Array<string>>([]);
  const [leadDetail, setLeadDetail] = useState<any>([]);
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [viewResponse, setViewResponse] = useState<boolean>(false);
  const [createNewLeadDrawer, showCreateNewLeadDrawer] =
    useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [formDetails, setFormDetails] = useState({});
  const [selectedLabel, setSelectedLabel] = useState<Array<string>>([]);
  const [formFields, setFormFields] = useState({});
  const [customFormResponse, setCustomFormResponse] = useState<Array<any>>([]);
  const [activityformTitle, setActivityFormTitle] = useState<string>("Add");
  const [showLabelPopup, setShowLabelPopup] = useState<boolean>(false);
  const [activityformName, setActivityFormName] = useState<string>("");
  const [selectedLead, setSelectedLead] = useState<LeadDataI>();
  const [taskDeteleId, setTaskDeleteId] = useState<string>("");
  const [showStatusPopup, setShowStatusPopup] = useState<boolean>(false);
  const [updateModalValue, setUpdateModalValue] = useState<any>({});
  const [taskShowConfirmationModal, setTaskShowConfirmationModal] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const StoreData = useSelector((state: any) => {
    return state?.rootReducers;
  });

  const deleteLeadFromDrawer = (id: string) => {
    setLeadToBeUpdate(id);
    setShowConfirmationModal(true);
    setToBeRemoved("lead");
    setOpenWithHeader(false);
  };

  const copyLeadToList = () => {
    // setShowCopyLeadToList(!copyLeadToListModal);
  };

  const moveLeadToList = () => {
    setShowMoveLeadToList(!moveLeadToListModal);
  };

  const handleEditActivityModal = async (
    name: string,
    _id: any,
    action: string
  ) => {};

  const handleActivityModal = (title: string, name: string) => {
    let modalId = "";
    if (name === "Activity") {
      modalId = "#AddNewActivites";
      setActivityFormTitle(title);
    } else {
      modalId = "#AddNewTask";
      setActivityFormTitle(title);
    }
    $(modalId).modal("show");
    setActivityFormName(name);
  };

  const onLeadFileUpload = async (e: any) => {
    let formData = new FormData();
    formData.append("files", e.target.files[0]);
    formData.append("type", "lead");
    formData.append("lead", leadId);
    try {
      const response = await uploadFiles(formData);
      if (response && response.status) {
        const tempArray = [...StoreData?.leadFile?.leadFile];
        tempArray?.unshift(response?.data?.data[0]);
        dispatch(leadFileList(tempArray));
        toast.success(response?.data?.message);
      }
    } catch (err) {
      toast.error("Error while uploading file!");
    }
  };
  const leadFileDeleteToggle = (objData: any) => {
    // setLeadFileObj(objData);
    // fileToggleModal();
  };

  const editLead = (e: { stopPropagation: () => void }, id: any) => {
    e.stopPropagation();
    showCreateNewLeadDrawer(true);
    setOpenWithHeader(false);
    setIsEditMode(true);
    setLeadToBeUpdate(id);
  };

  const getLeadData = async (id: string) => {
    setOpenWithHeader(true);
    try {
      const response = await getLeadDetail(id);
      if (response && response.status) {
        let responseData = response?.data?.data;
        setLeadDetail(responseData);
        let leadOwnerName =
          StoreData?.userData?.userDetails?.organizationEmployee?.find(
            (x: any) => x._id == responseData.createdBy
          );
        let first_name = leadOwnerName?.firstName;
        let last_name = leadOwnerName?.lastName ? leadOwnerName?.lastName : "";
        dispatch(setActivityList(responseData?.activities));
        dispatch(setLeadTaskList(responseData?.tasks));
        dispatch(setNote(responseData?.notes));
        dispatch(leadFileList(responseData?.files));
        //   setExtraDetails(responseData.extraDetails);
        //   setLeadOwnerName(first_name + " " + last_name);
        //   setSelectedLeadData({
        //     ...selectedLeadData,
        //     activities: responseData?.activities,
        //     label: responseData?.label,
        //     notes: responseData?.notes,
        //     tasks: responseData?.tasks,
        //     phone: responseData?.phone,
        //     email: responseData?.email,
        //   });
        setOpenWithHeader(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getLeads = useCallback(async () => {
    // try {
    //   setIsLoading(true);
    //   const response = await getAllLeads({
    //     isAscending: false,
    //     page: 1,
    //     perPage: 10,
    //   });
    //   if (response && response.status) {
    //     let responseData = response?.data;
    //     setLeadCounts(responseData.total);
    //     setTotalLead(responseData.data?.length);
    //     setCurrentPage(1);
    //     setLeadListData(responseData?.data);
    //     dispatch(setLeads(responseData?.data));
    //     setIsLoading(false);
    //     setIsFilterData(false);
    //   }
    // } catch (err) {
    //   setIsLoading(false);
    // }
  }, [dispatch]);

  const onTaskDelete = (id: string) => {
    let modalId = "#AddNewTask";
    setActivityFormTitle("Add");
    $(modalId).modal("hide");
    setTaskDeleteId(id);
    setTaskShowConfirmationModal(true);
  };
  // const onLabelClick = () => {
  //   setOpenWithHeader(true);
  // };

  const onLabelClick = () => {
    setShowLabelPopup(true);
  };
  const onStatusClick = () => {
    setShowStatusPopup(true);
  };
  const toggleLeadStatus = () => {
    setShowStatusPopup(!showStatusPopup);
    setSelectedStatus("");
  };
  const onStatusChange = (e: any) => {
    setSelectedStatus(e.target.value);
  };

  const toggleLeadLabel = () => {
    setShowLabelPopup(!showLabelPopup);
  };
  const onLabelChange = async (e: any) => {
    // let Value = e.target.value;
    // let newArray: any = [...selectedLabel];
    // let labelArray: any = [...leadLabelData];
    // let tempObj: any = {};
    // tempObj = labelArray.find((x: any) => x.value === Value);
    // if (tempObj?.isLabelChecked) {
    //   tempObj.isLabelChecked = false;
    // } else {
    //   tempObj.isLabelChecked = true;
    // }
    // if (selectedLabel.includes(Value)) {
    //   let index = newArray.findIndex((d: string) => d === Value);
    //   newArray.splice(index, 1);
    // } else {
    //   newArray = newArray.filter((d: string) => d !== Value);
    //   newArray.push(Value);
    // }
    // setLeadLabelData(await labelArray);
    // setSelectedLabel(newArray);
  };

  useEffect(() => {
    // console.log("===tableData========", tableData)
    if (tableData._id) {
      setLeadData(tableData.leads);
      // getAllFormData
      getAllCustomFormResponse(tableData?._id);
    }
    if (tableData.form) {
      // setFormDetails(tableData)
    }
  }, [tableData]);

  const getAllCustomFormResponse = async (formId: any) => {
    const response = await getCustomFormResponse(formId);

    if (response.data) {
      setCustomFormResponse(response.data.data);
    }
  };

  const openFormResponseDetails = (leadId: any) => {
    const formResponseData = customFormResponse.find(
      (item) => item.leadId == leadId
    );
    setFormDetails(formResponseData);
    setFormFields(tableData.form);
    setViewResponse(true);
  };

  return (
    <Root className="row mt-2">
      <div className="followups-section-1 Quotation">
        <table className="table table-borderless">
          <thead style={{ boxShadow: "none" }}>
            <tr>
              <th scope="col">Lead Name </th>
              <th scope="col">Actions</th>
              <th scope="col">Assigned to</th>
              <th scope="col">Created at</th>
              <th scope="col">Last activity</th>
            </tr>
          </thead>
          <tbody>
            {leadData?.map((item: any, index: number) => {
              // console.log('itemssssss======>',item)
              return (
                <tr style={{ boxShadow: "none" }} key={index}>
                  <td
                    scope="row"
                    data-label="Lead Name"
                    onClick={() => {
                      getLeadData(item._id);
                    }}
                  >
                    {item.name}
                  </td>
                  <td data-label="Actions" className="leads-section-3-sub-1-2">
                    <ul className="m-0">
                      <li>
                        <a href={`tel:${item?.phone}`} target="_blank">
                          <img alt="tel" src="assets/img/telephone.png" />
                        </a>
                      </li>
                      <li>
                        <a href={`mailto:${item?.email}`}>
                          <img alt="mail" src={"assets/img/email.png"} />
                        </a>
                      </li>
                      <li>
                        <a
                          href={`https://wa.me/${item?.phone}`}
                          target="_blank"
                        >
                          <img alt="wp" src="assets/img/whatsapp.png" />
                        </a>
                      </li>
                      <li
                        onClick={() => {
                          openFormResponseDetails(item._id);
                        }}
                      >
                        <a>
                          <img alt="wp" src="assets/img/redirect.png" />
                        </a>
                      </li>
                    </ul>
                  </td>
                  <td
                    data-label="Assigned to"
                    onClick={() => {
                      getLeadData(item._id);
                    }}
                  >
                    {item?.assignedTo?.firstName}{" "}
                  </td>
                  <td
                    data-label="Created at"
                    onClick={() => {
                      getLeadData(item._id);
                    }}
                  >
                    {moment(item.createdAt).format("Do MMM YYYY")} at{" "}
                    {moment(item.createdAt).format("hh:mm A")}
                  </td>
                  <td
                    data-label="Last activity"
                    onClick={() => {
                      getLeadData(item._id);
                    }}
                  >
                    {moment(item.lastActivity).format("Do MMM YYYY")} at{" "}
                    {moment(item.lastActivity).format("hh:mm A")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="leadDetail_drawer">
        <DrawerComponent
          openWithHeader={openWithHeader}
          setOpenWithHeader={setOpenWithHeader}
          drawerTitle="Lead Details"
          size="lg"
        >
          <LeadDetails
            _id={leadDetail?._id || ""}
            name={leadDetail?.name || ""}
            integration={leadDetail?.integration || ""}
            customSource={leadDetail?.customSource || ""}
            createdAt={leadDetail?.createdAt || new Date()}
            status={leadDetail?.status || []}
            saleValue={leadDetail?.saleValue || ""}
            website={leadDetail?.website || ""}
            label={leadDetail?.label || []}
            deleteLeadFromDrawer={deleteLeadFromDrawer}
            copyLeadToList={copyLeadToList}
            moveLeadToList={moveLeadToList}
            onLabelClick={onLabelClick}
            onStatusClick={onStatusClick}
            setFrom={setFrom}
            handleEditModal={handleEditActivityModal}
            handleLeadDetailsModalOpen={handleActivityModal}
            onLeadFileUpload={onLeadFileUpload}
            editLead={editLead}
            leadFileDelete={leadFileDeleteToggle}
          />
        </DrawerComponent>

        <SelectLeadStatus
          showStatusPopup={showStatusPopup}
          toggleLeadStatus={toggleLeadStatus}
          onStatusChange={onStatusChange}
          selectedStatus={selectedStatus}
          setShowStatusPopup={setShowStatusPopup}
          from={from}
          id={selectedLead?._id || ""}
          leadList={""}
          getLeads={getLeads}
          setOpenWithHeader={setOpenWithHeader}
        />
        <SelectLeadLabel
          showLabelPopup={showLabelPopup}
          toggleLeadLabel={toggleLeadLabel}
          onLabelChange={onLabelChange}
          selectedLabel={selectedLabel}
          setShowLabelPopup={setShowLabelPopup}
          from={from}
          leadList={""}
          id={selectedLead?._id || ""}
          getLeads={getLeads}
          setOpenWithHeader={setOpenWithHeader}
          LabelData={leadLabelData}
        />

        {/* {selectedLead && (
          <CreateLeadDetailsTaskForm
            leadIds={[{ id: selectedLead?._id }]}
            modalId="#AddNewTask"
            updateModalValue={updateModalValue}
            action={activityformTitle}
            formName={activityformName}
            onTaskDelete={onTaskDelete}
          />
        )} */}

        {viewResponse && (
          <FormResponse
            open={viewResponse}
            handleClose={() => {
              setViewResponse(false);
            }}
            formDetails={formDetails}
            formFields={formFields}
          />
        )}
      </div>
    </Root>
  );
};
export default FormTable;
const Root = styled.div`
  th,
  td {
    text-transform: capitalize;
  }
  @media screen and (max-width: 600px) {
    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    table tr {
      display: block;
      border: none;
      border-radius: 0px;
    }

    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: 0.8em;
      text-align: right;
      border-radius: 0px;
    }

    table td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }

    table td:last-child {
      border-bottom: 0;
    }
  }
`;
