import { ColDef, GridApi } from "ag-grid-community";
import _, { isEqual } from "lodash";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  setLeadCount,
  setLeadFilter,
  setLeadIds,
  userPreferences,
} from "../../../actions/actions";
import {
  assignLeadInList,
  copyLeadInList,
  deleteBulkLeads,
  filterLeads,
  getAllLeads,
  moveLeadInList,
  putUserPreferences,
} from "../../../services/leadService";
import { areAllValuesUndefined } from "../../../utils/helpers";
import AgGrid, { IFetchRowsParams } from "../../common/grid/AgGrid";
import { CustomFieldsT } from "../customLeadForm";
import {
  assignedToRenderer,
  dateRenderer,
  labelRenderer,
  lastActivityRenderer,
  locationRenderer,
  nameRenderer,
  phoneRenderer,
  sourceRenderer,
  statusRenderer,
} from "./leads-renderers";
import { FilterParams, advanceFilterKeys } from "./leads.types";
import ContactLinksGenerator from "../../../utils/contactlinksgenerator";
import TaskDrawer from "../../common/TaskDrawer";
import CreateTaskForm from "../../../components/createTaskForm";
import CreateNoteForm from "../../../components/createNoteForm";
import { MdAddTask } from "react-icons/md";
import { MdOutlineNoteAdd } from "react-icons/md";
import BulkSelector from "../../../components/BulkSelector/bulkSelector";
import BulkLeadOptions from "../../../components/BulkLeadOptions/bulkLeadOptions";
import { useNavigate } from "react-router-dom";
import { setManuallyAutomate } from "../../../services/automationService";

export interface PreferenceI {
  status: Array<any>;
  labels: Array<any>;
  leadGridPreferences: {
    columnOrders: string[];
    columnWidths: { [colId: string]: number };
  };
  customForm: Array<CustomFieldsT>;
}

export interface ILeadsGridProps {
  handleLeadClick: (rowData: any) => void;
  leadListData: Array<any>;
  setLeadListData: React.Dispatch<React.SetStateAction<any[]>>;
  onEditStatus: () => void;
  onEditLabel: () => void;
  selectedItemCount: number;
  setSelectedItemCount: any;
  showBulk: boolean;
  onResetBulk: () => void;
}

const LeadsGrid = forwardRef(
  (
    {
      handleLeadClick,
      leadListData,
      setLeadListData,
      onEditStatus,
      onEditLabel,
      selectedItemCount,
      setSelectedItemCount,
      showBulk,
      onResetBulk,
    }: ILeadsGridProps,
    ref
  ) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [resetGrid, setResetGrid] = useState<number>(0);
    const [openWithHeader, setOpenWithHeader] = useState<boolean>(false);
    // const [selectedItemCount, setSelectedItemCount] = useState<number>(0);

    const { filterParam, leadCount } = useSelector(
      (state: any) => state.rootReducers.leads
    );
    const gridApi = useRef<GridApi | null>(null);
    const preferenceRef = useRef<PreferenceI | null>(null);
    const StoreData = useSelector((state: any) => {
      return state?.rootReducers;
    });

    const preferences: PreferenceI = useSelector(
      (state: { rootReducers: { user: { userPreferences: any } } }) =>
        state?.rootReducers?.user?.userPreferences
    );

    const debouncedColumnMove = useRef(
      _.debounce((newColumnOrder, preferences) => {
        onColumnOrderChanged &&
          onColumnOrderChanged(newColumnOrder, preferences);
      }, 500)
    ).current;

    const debouncedColumnWidth = useRef(
      _.debounce((columnId, newWidth, preferences) => {
        onColumnWidthChanged &&
          onColumnWidthChanged(columnId, newWidth, preferences);
      }, 500)
    ).current;

    const defaultColumnDefs: ColDef[] = [
      // {
      //   headerName: "S.no.",
      //   colId: "sno",
      //   field: "sno",
      //   cellClass: "lead_cell",
      //   sortable: false,
      //   width: 60,
      //   valueGetter: "node.rowIndex + 1",
      //   headerCheckboxSelection: true,
      //   checkboxSelection: true,
      // },
      // {
      //   headerName: "S.no.",
      //   field: "sno",
      //   valueGetter: "node.rowIndex + 1",
      //   headerCheckboxSelection: true,
      //   checkboxSelection: true,
      //   cellClass: "lead_cell",
      //   sortable: false,
      //   cellRendererParams: {
      //     checkbox: true,
      //   },
      //   // headerComponent: () => (
      //   //   <>
      //   //     <input type="checkbox" />
      //   //     <span className="ag-header-cell-text">S.no.</span>
      //   //   </>
      //   // ),
      // },
      {
        headerName: "Name",
        sortable: false,
        width: 150,
        field: "name",
        colId: "name",
        cellRenderer: nameRenderer,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      {
        headerName: "Actions",
        sortable: false,
        width: 500,
        field: "phone",
        colId: "phone",
        cellRenderer: phoneRenderer,
        // cellRenderer: (params: any) => phoneRender({ ...params }),
      },
      {
        headerName: "Status",
        sortable: false,
        width: 150,
        field: "status",
        colId: "status",
        cellRenderer: (params: any) =>
          statusRenderer({ ...params, preferences }),
      },
      {
        headerName: "Source",
        sortable: false,
        width: 180,
        field: "integration",
        colId: "integration",
        cellRenderer: sourceRenderer,
      },
      {
        headerName: "Last Activity",
        sortable: false,
        width: 250,
        field: "lastActivity",
        colId: "lastActivity",
        cellRenderer: lastActivityRenderer,
      },
      {
        headerName: "Assigned To",
        sortable: false,
        width: 200,
        field: "assignedTo",
        colId: "assignedTo",
        cellRenderer: assignedToRenderer,
      },
      {
        headerName: "Label",
        sortable: false,
        width: 220,
        field: "label",
        colId: "label",
        cellRenderer: (params: any) =>
          labelRenderer({ ...params, preferences }),
      },
    ];

    const [columnDefs, setColumnDefs] = useState<ColDef[]>(defaultColumnDefs);

    const fetchLeadListOnScroll = (
      params: IFetchRowsParams,
      leadName?: string,
      search?: string
    ) => {
      let updatedFilterParams: Partial<FilterParams> = {
        sort: { orderBy: params.orderBy, isAscending: params.isAscending },
        ...filterParam,
        paginationParams: { perPage: params.perPage, page: params.page },
      };

      dispatch(setLeadFilter(updatedFilterParams));

      let lead_name = leadName || StoreData.leadName.leadName.id;
      return fetchLeads(
        updatedFilterParams,
        lead_name !== "0" ? lead_name : undefined
      );
    };

    const handleRowClick = (rowData: any) => {
      handleLeadClick(rowData);
    };

    const comparePreferencesAreEqual = (newPref: any, oldPref: any) => {
      return isEqual(newPref, oldPref);
    };

    const onColumnOrderChanged = (
      orders: string[],
      preferences: PreferenceI
    ) => {
      const uniqueOrders = [...new Set(orders)];
      const leadGridPreferences = {
        ...preferences.leadGridPreferences,
        columnOrders: uniqueOrders,
      };
      if (
        !comparePreferencesAreEqual(
          leadGridPreferences,
          preferences.leadGridPreferences
        )
      ) {
        dispatch(
          userPreferences({
            ...preferences,
            leadGridPreferences,
          })
        );
        handleAddGridPreferences(leadGridPreferences);
      }
    };

    const onColumnWidthChanged = (
      colId: string,
      newWidth: number,
      preferences: PreferenceI
    ) => {
      const updatedColumnWidths = {
        ...(preferences.leadGridPreferences?.columnWidths || {}),
        [colId]: newWidth,
      };

      const leadGridPreferences = {
        ...preferences.leadGridPreferences,
        columnWidths: updatedColumnWidths,
      };

      if (
        !comparePreferencesAreEqual(
          leadGridPreferences,
          preferences.leadGridPreferences
        )
      ) {
        dispatch(
          userPreferences({
            ...preferences,
            leadGridPreferences,
          })
        );
        handleAddGridPreferences(leadGridPreferences);
      }
    };

    const handleAddGridPreferences = async (data: any) => {
      try {
        const response = await putUserPreferences({
          leadGridPreferences: data,
        });
        if (response && response.status) {
          console.log(response.data.message);
        }
      } catch (err: any) {
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error("Error while updating Source!");
        }
      }
    };

    const fetchLeadsWrapper = async () => {
      setResetGrid((prev) => prev + 1);
    };

    const fetchLeads = async (filterParam: FilterParams, leadName: string) => {
      try {
        setIsLoading(true);
        let response;
        if (
          Object.keys(filterParam).some((key) =>
            advanceFilterKeys.includes(key)
          ) &&
          !areAllValuesUndefined(filterParam, advanceFilterKeys)
        ) {
          response = await filterLeads({ ...filterParam, list: leadName });
        } else {
          response = await getAllLeads({
            isAscending: false,
            page: filterParam.paginationParams?.page,
            perPage: filterParam.paginationParams?.perPage,
            list: leadName,
          });
        }
        if (response && response.status) {
          let responseData = response?.data;
          setLeadListData(responseData.data);
          dispatch(setLeadCount(responseData.total));
          return { data: responseData.data, totalRows: responseData.total };
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };

    const getColunmmnDefs = () => {
      return columnDefs;
    };

    const refreshCurrentPageInGridFn = (updatedRows: any) => {
      const idToDataMap: { [key: string]: any } = {};
      for (const updatedRow of updatedRows) {
        idToDataMap[updatedRow._id] = updatedRow;
      }

      gridApi.current?.forEachNode((node) => {
        const leadData = node.data;
        if (idToDataMap[leadData._id]) {
          node.setData(idToDataMap[leadData._id]);
        }
      });
    };

    const onGridReady = (params: any) => {
      gridApi.current = params.api;
    };

    useEffect(() => {
      if (!preferences) return;
      preferenceRef.current = preferences;
      const columnOrders = preferences.leadGridPreferences?.columnOrders || [];
      let columnWidths = preferences.leadGridPreferences?.columnWidths || {};

      // columnWidths["phone"] = 250;

      let orderedColumnDefs = [];
      if (columnOrders.length === 0) {
        orderedColumnDefs = defaultColumnDefs.map((def) => {
          const width = columnWidths[def.field || ""];

          return {
            ...def,
            width: width || def.width,
          };
        });
      } else {
        orderedColumnDefs = columnOrders.map((colId) => {
          let columnDef = defaultColumnDefs.find((def) => def.field === colId);
          if (
            !columnDef &&
            preferences.customForm &&
            preferences.customForm.length
          ) {
            const form = preferences.customForm.find(
              (form) => form.value === colId
            );
            if (form) {
              let cellRenderer;
              if (form.type === "location") {
                cellRenderer = locationRenderer;
              } else if (form.type === "date") {
                cellRenderer = dateRenderer;
              }
              let field = `extraDetails.${form.value}`;
              if (form.value === "saleValue") {
                field = "saleValue";
              } else if (form.value === "email") {
                field = "email";
              }
              columnDef = {
                headerName: form.label,
                sortable: false,
                width: 150,
                field: field,
                colId: form.value,
                cellRenderer,
              };
            }
          }
          if (columnDef) {
            const width = columnWidths[colId];
            return {
              ...columnDef,
              width: width || columnDef.width,
            };
          }

          return null;
        });
      }

      setColumnDefs(
        orderedColumnDefs.filter((colDef) => colDef !== null) as ColDef[]
      );
    }, [preferences]);

    // Expose fetchLeadListOnScroll function using useImperativeHandle
    useImperativeHandle(ref, () => ({
      fetchLeadsWrapper,
      refreshCurrentPageInGridFn,
      getColunmmnDefs,
      getSelectedLeadIds,
      handleSelection,
      handleUnSelect,
    }));

    const [action, setAction] = useState<string>("");
    const [leadId, setLeadId] = useState<string>("");

    // Function to select the next set of items
    const selectNextItems = (
      startIndex: number,
      total: number,
      allNodes: any[]
    ) => {
      const endIndex = Math.min(startIndex + total, allNodes.length);

      // Select the next rows
      for (let i = startIndex; i <= endIndex; i++) {
        allNodes[i]?.setSelected(true);
      }

      // Scroll to the last selected row
      if (endIndex > startIndex) {
        gridApi?.current?.ensureIndexVisible(endIndex - 1, "bottom");
      }

      return endIndex;
    };

    function isNearby(num1: number, num2: number, tolerance = 3) {
      return Math.abs(num1 - num2) <= tolerance;
    }

    const handleSelection = (total: number) => {
      if (!gridApi?.current) return;

      // const selectedNodes: any = gridApi.current.getSelectedNodes();

      let currentPageRows = gridApi.current.getRenderedNodes();

      let startIndex = 0;

      currentPageRows.forEach((node: any, i: number) => {
        if (node.selected) {
          startIndex = i;
        }
      });

      // Determine the starting index
      // const startIndex =
      //   selectedNodes.length > 0
      //     ? selectedNodes[selectedNodes.length - 1].rowIndex + 1
      //     : 0;

      startIndex = startIndex === -1 ? 0 : startIndex;

      let isAllSelected = gridApi.current
        .getRenderedNodes()
        .every((node: any) => node.selected);

      if (
        isAllSelected ||
        isNearby(startIndex, gridApi.current.getRenderedNodes()?.length)
      ) {
        gridApi.current.paginationGoToNextPage();

        // Retry selection after the next page loads
        setTimeout(() => {
          const newNodes: any = [];
          gridApi?.current
            ?.getRenderedNodes()
            .map((node) => newNodes.push(node));
          selectNextItems(0, total, newNodes); // Start from the beginning of the new page
        }, 500); // Adjust timeout based on page load time

        return;
      }

      // Get all rendered nodes
      const allNodes: any = [];
      gridApi.current.getRenderedNodes()?.map((node) => allNodes.push(node));

      // Select the next set of rows
      const endIndex = selectNextItems(startIndex, total, allNodes);

      // debugger;

      // If there are no more rows on the current page, navigate to the next page
      if (
        startIndex >= endIndex &&
        gridApi?.current?.paginationGoToNextPage
        //  ||
        // endIndex === gridApi.current.getRenderedNodes()?.length
      ) {
        gridApi.current.paginationGoToNextPage();

        // Retry selection after the next page loads
        setTimeout(() => {
          const newNodes: any = [];
          gridApi?.current
            ?.getRenderedNodes()
            .map((node) => newNodes.push(node));
          selectNextItems(0, total, newNodes); // Start from the beginning of the new page
        }, 500); // Adjust timeout based on page load time
      }
    };

    const handleUnSelect = () => {
      if (!gridApi?.current) return;

      gridApi?.current.forEachNode((node) => {
        node.setSelected(false);
      });

      gridApi?.current?.deselectAll();

      setSelectedItemCount(0);
      gridApi?.current?.paginationGoToPage(0);
      onResetBulk();
    };

    const onRowSelected = (event: any) => {
      if (event.node.selected) {
        setSelectedItemCount((prev: number) => prev + 1);
      } else {
        setSelectedItemCount((prev: number) => (prev === 0 ? 0 : prev - 1));
      }
    };

    const getSelectedLeadIds = () => {
      const selectedNodes: any = gridApi?.current?.getSelectedNodes();

      return selectedNodes?.map((e: any) => e.data._id);
    };

    const onDeleteLead = async () => {
      try {
        const leadIDs = getSelectedLeadIds();
        const response = await deleteBulkLeads({ leadIDs });
        reloadLeads();
      } catch (error) {}
    };

    const handleAssignLead = async (assignToUser: string) => {
      try {
        const leadIds = getSelectedLeadIds();
        const { data } = await assignLeadInList({ leadIds, assignToUser });

        toast.success(data?.message);
        reloadLeads();
      } catch (error) {
        console.log("err", error);
      }
    };

    const handleCopyLead = async (
      targetListId: string,
      activeLeadOption: string
    ) => {
      try {
        const data = {
          leadIDs: getSelectedLeadIds(),
          targetListId,
        };

        if (activeLeadOption == "copy") {
          const response = await copyLeadInList(data);
          toast.success(response?.data?.message);
        } else {
          const response = await moveLeadInList(data);
          toast.success(response?.data?.message);
        }

        reloadLeads();
      } catch (err) {}
    };

    const handleSelectAutomate = async (automationId: string) => {
      try {
        const data = {
          leadIds: getSelectedLeadIds(),
          automationId,
        };

        const response = await setManuallyAutomate(data);
        toast.success(response?.data?.message);

        reloadLeads();
      } catch (err) {}
    };

    const reloadLeads = () => {
      gridApi.current?.refreshCells({ force: true });
      fetchLeadsWrapper();
    };

    const onCreateCampaign = () => {
      const leadIds = getSelectedLeadIds();
      dispatch(setLeadIds(leadIds));
      navigate("/createWhatsappBroadcast");
      reloadLeads();
    };

    return (
      <>
        {(showBulk || selectedItemCount > 0) && (
          <div
            style={{
              marginTop: 0,
              position: "relative",
              top: "10px",
              marginBottom: 20,
            }}
          >
            <BulkSelector
              selected={selectedItemCount}
              onSelect={handleSelection}
              onReset={handleUnSelect}
            />
          </div>
        )}

        {selectedItemCount ? (
          <BulkLeadOptions
            onDeleteLead={onDeleteLead}
            handleAssignLead={handleAssignLead}
            handleCopyLead={handleCopyLead}
            onEditStatus={onEditStatus}
            onEditLabel={onEditLabel}
            onBulkTask={() => {
              setOpenWithHeader(true);
              setAction("addTask");
            }}
            onCreateCampaign={onCreateCampaign}
            handleSelectAutomate={handleSelectAutomate}
          />
        ) : null}

        <AgGrid
          columnDefs={columnDefs}
          fetchData={fetchLeadListOnScroll}
          onRowClick={handleRowClick}
          defaultData={leadListData}
          totalRows={leadCount}
          isLoading={isLoading}
          onColumnOrderChanged={(newColumnOrder) =>
            debouncedColumnMove(newColumnOrder, preferenceRef.current)
          }
          onColumnWidthChanged={(columnId, newWidth) =>
            debouncedColumnWidth(columnId, newWidth, preferenceRef.current)
          }
          key={resetGrid}
          onGridReady={onGridReady}
          onRowSelected={onRowSelected}
        />

        <TaskDrawer
          openWithHeader={openWithHeader}
          setOpenWithHeader={setOpenWithHeader}
          onTaskDelete={() => {}}
          showDelete={false}
          drawerTitle={action === "addTask" ? "Add Task" : "Add Note"}
          size="xs"
        >
          {action === "addTask" && (
            <CreateTaskForm
              leadId={getSelectedLeadIds()}
              updateTaskValue={{}}
              action={action === "addTask" ? "add" : "Add Note"}
              status={""}
              drawerClose={() => {
                setOpenWithHeader(false);
                setAction("");
                handleUnSelect();
              }}
              fetchTaskStatusFollowUps={() => {}}
              // fetchTaskStatusFollowUps={handleTaskFollowup}
            />
          )}

          {action === "addNotes" && (
            <CreateNoteForm
              leadIds={[{ id: leadId }]}
              handleNotesSubmit={() => {
                setOpenWithHeader(false);
                setAction("");
              }}
            />
          )}
        </TaskDrawer>
      </>
    );
  }
);

export default LeadsGrid;
