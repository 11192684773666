import Header from "../../components/header";
import DialogBox from "../../components/dialogBox";
import { useEffect, useState } from "react";
import Select from "react-select";
import InputMaps from "../../components/InputMaps";
import Inputs from "../../components/inputs";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Spin } from "antd";
import { Row, Col, Toggle, Uploader, Input, DateRangePicker } from "rsuite";
import DrawerComponent from "../../components/drawer";
import UserForm from "./userForm";
import PhoneInput from "react-phone-input-2";
import InputStyledComp from "../../components/webForm/InputStyledComp";
import MultipleOption from "../../components/webForm/MultipleOption";
import toast, { Toaster } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faUpload } from "@fortawesome/free-solid-svg-icons";
import {
  createCustomForm,
  editCustomForm,
  formLeadList,
  getFormList,
  getFormListById,
  uploadFile,
} from "../../services/formService";
import ConfirmationModal from "../../components/confirmationModal";
import moment from "moment";
import { useSelector } from "react-redux";
import PaymentFiels from "../../components/webForm/PaymentFiels";
import DynamicConfirmationModal from "../../components/DynamicConfirmationModal";
import styled from "styled-components";
interface FormData {
  name: string;
  listId: string;
  description: string;
  form: any[];
  createdAt: any;
  createdBy: string;
  // _id:string;
  // type: string;
  // uniqueCode: string;
  // listName: string;
}

const UserList: React.FC = () => {
  const initialFormData: FormData = {
    // _id:'',
    name: "",
    description: "",
    listId: "",
    createdAt: "",
    createdBy: "",
    form: [],
    // uniqueCode:''
  };

  const [formOpen, setFormOpen] = useState(false);
  const [fullScreenForm, setFullScreenForm] = useState(false);
  const [questionBox, setQuestionBox] = useState(false);

  const [activeField, setActiveField] = useState("");
  const [openWithHeader, setOpenWithHeader] = useState<boolean>(false);
  const [fieldModal, setFieldModal] = useState<boolean>(false);
  const [isPageLoad, setIsPageLoad] = useState<boolean>(false);
  const [formCloseModel, setFormCloseModel] = useState<boolean>(false);
  const [stepFormModel, setStepFormModel] = useState<boolean>(false);
  const [userList, setUserList] = useState<any>([]);
  const [activeQuestion, setActiveQuestion] = useState<any>('');
  const [activeFormId, setActiveFormId] = useState<string>("");
  const [totalUser, setTotalUser] = useState(0);
  const [userType, setUserType] = useState<string>("Create");
  const [userName, setUserName] = useState('')
  const [updateFormData, setUpdateFormData] = useState<FormData>({
    name: "",
    description: "",
    listId: "",
    form: [],
    createdAt: "",
    createdBy: "",
  });
  const [newFormData, setNewFormData] = useState<any>({
    name: "",
    formType: "lead",
    list: "",
    description: "",
    form: [],
  });

  const [leadList, setLeadList] = useState([])
  const [activeOption, setActiveOption] = useState("");

  const { id } = useParams();

  const leadName = useSelector((state: any) => {
    return state?.rootReducers;
  });

  const handleCreateForm = async () => {
    // validations
    if (newFormData.name.length < 3) {
      return toast.error("Please Enter Form Name");
    }
    // else if (newFormData.description.length < 10) {
    //     return toast.error("Please Enter Descripton");
    // }
    setUserType("Create");
    setFormOpen(false);
    setFullScreenForm(true);
  };

  const updateCreateForm = (type: string, value: any) => {
    const data = { ...newFormData, [type]: value };
    setNewFormData(data);
    // setUpdateFormData(data)
  };

  const handleClickUser = async (id: string) => {
    setIsPageLoad(true);
    try {
      const response = await getFormListById(id);
      setIsPageLoad(false);
      if (response && response.status) {
        setOpenWithHeader(true);
        setActiveFormId(id);
        setUserType("Update");
        setUpdateFormData(response.data.data);
      }
    } catch (err) {
      setIsPageLoad(false);
      console.log(err, "Error");
    }
  };
  const handleFormList = async () => {
    try {
      setIsPageLoad(true);
      const response = await getFormList();
      setIsPageLoad(false);
      if (response && response.status) {
        setUserList(response.data.data.reverse());
        setTotalUser(response.data.total);
      }
    } catch (err) {
      setIsPageLoad(false);
      console.log(err, "Error");
    }
  };

  const handleAddQuestion = (value: string) => {

    let count = 0;
    if (userType == "Create") {
      newFormData.form.forEach((formItem: any) => {
        formItem.fields.forEach((field: any) => {
          if (field.type === value) {
            count++;
          }
        });
      });
    } else {
      updateFormData.form.forEach((formItem: any) => {
        formItem.fields.forEach((field: any) => {
          if (field.type === value) {
            count++;
          }
        });
      });
    }


    // console.log('count ====> name',count)
    setQuestionBox(false);
    if (value == "short_text") {
      const data = {
        question: "step",
        conditionRule: [],
        fields: [
          {
            fieldName: `Short text ${count == 0 ? '' : count}`,
            displayName: `Short text ${count == 0 ? '' : count}`,
            type: "short_text",
            placeholder: "Enter Text",
            description: "",
            required: false,
            validations: [
              {
                min: 4,
                message: "Name should be greater than 4 character",
              },
              {
                max: 20,
                message: "Name should be less than 20 characters",
              },
            ],
          },
        ],
      };
      if (userType == "Create") {
        const updateData = {
          ...data,
          question: `question_${newFormData.form.length + 1}`,
        };
        const updatedFormData = {
          ...newFormData,
          form: [...newFormData.form, updateData],
        };
        setNewFormData(updatedFormData);
      } else {
        const updateData = {
          ...data,
          question: `question_${updateFormData.form.length + 1}`,
        };
        const updatedFormData: FormData = {
          ...updateFormData,
          form: [...updateFormData.form, updateData],
        };
        setUpdateFormData(updatedFormData);
      }
    } else if (value == 'name') {
      const data = {
        question: "step",
        conditionRule: [],
        fields: [
          {
            fieldName: `Name ${count == 0 ? '' : count}`,
            displayName: `Name ${count == 0 ? '' : count}`,
            type: "short_text",
            placeholder: "Enter Text",
            description: "",
            required: false,
            validations: [
              {
                min: 4,
                message: "Name should be greater than 4 character",
              },
              {
                max: 20,
                message: "Name should be less than 20 characters",
              },
            ],
          },
        ],
      };
      if (userType == "Create") {
        const updateData = {
          ...data,
          question: `question_${newFormData.form.length + 1}`,
        };
        const updatedFormData = {
          ...newFormData,
          form: [...newFormData.form, updateData],
        };
        setNewFormData(updatedFormData);
      } else {
        const updateData = {
          ...data,
          question: `question_${updateFormData.form.length + 1}`,
        };
        const updatedFormData: FormData = {
          ...updateFormData,
          form: [...updateFormData.form, updateData],
        };
        setUpdateFormData(updatedFormData);
      }
    } else if (value == "phone") {
      const data = {
        question: "step",
        conditionRule: [],
        fields: [
          {
            fieldName: `Phone ${count == 0 ? '' : count}`,
            displayName: `Phone ${count == 0 ? '' : count}`,
            type: "phone",
            placeholder: "Enter phone number",
            description: "",
            required: false,
            validations: [
              {
                min: 7,
                message: "Phone should be greater than 7",
              },
              {
                max: 10,
                message: "Phone should be less than 10",
              },
            ],
          },
        ],
      };
      if (userType == "Create") {
        const updateData = {
          ...data,
          question: `question_${newFormData.form.length + 1}`,
        };
        const updatedFormData = {
          ...newFormData,
          form: [...newFormData.form, updateData],
        };
        setNewFormData(updatedFormData);
      } else {
        const updateData = {
          ...data,
          question: `question_${updateFormData.form.length + 1}`,
        };
        const updatedFormData: FormData = {
          ...updateFormData,
          form: [...updateFormData.form, updateData],
        };
        setUpdateFormData(updatedFormData);
      }
    } else if (value == "file_upload") {
      const data = {
        question: "step",
        conditionRule: [],
        fields: [
          {
            fieldName: `File Upload ${count == 0 ? '' : count}`,
            displayName: `File Upload ${count == 0 ? '' : count}`,
            type: "file_upload",
            placeholder: "Upload file ",
            description: "",
            required: false,
            validations: [
              {
                min: 100,
                message: "file size less then 100 mb each",
              },
              {
                max: 100,
                message: "file size less then 100 mb each",
              },
            ],
          },
        ],
      };

      if (userType == "Create") {
        const updateData = {
          ...data,
          question: `question_${newFormData.form.length + 1}`,
        };
        const updatedFormData = {
          ...newFormData,
          form: [...newFormData.form, updateData],
        };
        setNewFormData(updatedFormData);
      } else {
        const updateData = {
          ...data,
          question: `question_${updateFormData.form.length + 1}`,
        };
        const updatedFormData: FormData = {
          ...updateFormData,
          form: [...updateFormData.form, updateData],
        };
        setUpdateFormData(updatedFormData);
      }
    } else if (value == "email") {
      const data = {
        question: "step",
        conditionRule: [],
        fields: [
          {
            fieldName: `Email ${count == 0 ? '' : count}`,
            displayName: `Email ${count == 0 ? '' : count}`,
            type: "email",
            placeholder: "Enter valid email",
            description: "",
            required: false,
            validations: [
              {
                message: "valdi email with @ and . symbol",
              },
            ],
          },
        ],
      };
      if (userType == "Create") {
        const updateData = {
          ...data,
          question: `question_${newFormData.form.length + 1}`,
        };
        const updatedFormData = {
          ...newFormData,
          form: [...newFormData.form, updateData],
        };
        setNewFormData(updatedFormData);
      } else {
        const updateData = {
          ...data,
          question: `question_${updateFormData.form.length + 1}`,
        };
        const updatedFormData: FormData = {
          ...updateFormData,
          form: [...updateFormData.form, updateData],
        };
        setUpdateFormData(updatedFormData);
      }
    } else if (value == "long_text") {
      const data = {
        question: "step",
        conditionRule: [],
        fields: [
          {
            fieldName: `Long Text ${count == 0 ? '' : count}`,
            displayName: `Long Text ${count == 0 ? '' : count}`,
            type: "long_text",
            placeholder: "Enter Text",
            description: "",
            required: false,
          },
        ],
      };
      if (userType == "Create") {
        const updateData = {
          ...data,
          question: `question_${newFormData.form.length + 1}`,
        };
        const updatedFormData = {
          ...newFormData,
          form: [...newFormData.form, updateData],
        };
        setNewFormData(updatedFormData);
      } else {
        const updateData = {
          ...data,
          question: `question_${updateFormData.form.length + 1}`,
        };
        const updatedFormData: FormData = {
          ...updateFormData,
          form: [...updateFormData.form, updateData],
        };
        setUpdateFormData(updatedFormData);
      }
    } else if (value == "number") {
      const data = {
        question: "step",
        conditionRule: [],
        fields: [
          {
            fieldName: `Number ${count == 0 ? '' : count}`,
            displayName: `Number ${count == 0 ? '' : count}`,
            type: "number",
            placeholder: "Enter number",
            description: "",
            required: false,
            validations: [
              {
                min: 4,
                message: "Name should be greater than 4 character",
              },
              {
                max: 20,
                message: "Name should be less than 20 characters",
              },
            ],
          },
        ],
      };
      if (userType == "Create") {
        const updateData = {
          ...data,
          question: `question_${newFormData.form.length + 1}`,
        };
        const updatedFormData = {
          ...newFormData,
          form: [...newFormData.form, updateData],
        };
        setNewFormData(updatedFormData);
      } else {
        const updateData = {
          ...data,
          question: `question_${updateFormData.form.length + 1}`,
        };
        const updatedFormData: FormData = {
          ...updateFormData,
          form: [...updateFormData.form, updateData],
        };
        setUpdateFormData(updatedFormData);
      }
    } else if (value == "date") {
      const data = {
        question: "step",
        conditionRule: [],
        fields: [
          {
            fieldName: `Date ${count == 0 ? '' : count}`,
            displayName: `Date ${count == 0 ? '' : count}`,
            type: "date",
            placeholder: "Select Date",
            description: "",
            required: false,
            validations: [
              {
                min: "2023-12-20T00:00:00.000Z",
                message: "Must like this",
              },
            ],
          },
        ],
      };
      if (userType == "Create") {
        const updateData = {
          ...data,
          question: `question_${newFormData.form.length + 1}`,
        };
        const updatedFormData = {
          ...newFormData,
          form: [...newFormData.form, updateData],
        };
        setNewFormData(updatedFormData);
      } else {
        const updateData = {
          ...data,
          question: `question_${updateFormData.form.length + 1}`,
        };
        const updatedFormData: FormData = {
          ...updateFormData,
          form: [...updateFormData.form, updateData],
        };
        setUpdateFormData(updatedFormData);
      }
    } else if (value == "address") {
      const data = {
        question: "step",
        conditionRule: [],
        fields: [
          {
            fieldName: `Enter Address ${count == 0 ? '' : count}`,
            displayName: `Enter Address ${count == 0 ? '' : count}`,
            type: "address",
            placeholder: "Enter Address",
            description: "",
            required: false,
            validations: [
              {
                min: 7,
                message: "Phone should be greater than 7",
              },
              {
                max: 10,
                message: "Phone should be less than 10",
              },
            ],
          },
        ],
      };
      if (userType == "Create") {
        const updateData = {
          ...data,
          question: `question_${newFormData.form.length + 1}`,
        };
        const updatedFormData = {
          ...newFormData,
          form: [...newFormData.form, updateData],
        };
        setNewFormData(updatedFormData);
      } else {
        const updateData = {
          ...data,
          question: `question_${updateFormData.form.length + 1}`,
        };
        const updatedFormData: FormData = {
          ...updateFormData,
          form: [...updateFormData.form, updateData],
        };
        setUpdateFormData(updatedFormData);
      }
    } else if (value == "multiple_options") {
      const data = {
        question: "step",
        conditionRule: [],
        fields: [
          {
            fieldName: `Multiple Option ${count == 0 ? '' : count}`,
            displayName: `Multiple Option ${count == 0 ? '' : count}`,
            type: "multiple_options",
            placeholder: "Select option",
            description: "",
            required: false,
            multipleSelection: false,
            options: [
              {
                displayName: "Option_1",
                value: "",
              },
            ],
          },
        ],
      };
      if (userType == "Create") {
        const updateData = {
          ...data,
          question: `question_${newFormData.form.length + 1}`,
        };
        const updatedFormData = {
          ...newFormData,
          form: [...newFormData.form, updateData],
        };
        setNewFormData(updatedFormData);
      } else {
        const updateData = {
          ...data,
          question: `question_${updateFormData.form.length + 1}`,
        };
        const updatedFormData: FormData = {
          ...updateFormData,
          form: [...updateFormData.form, updateData],
        };
        setUpdateFormData(updatedFormData);
      }
    } else if (value == "end_screen") {
      const data = {
        question: "step",
        conditionRule: [],
        fields: [
          {
            fieldName: "end_screen",
            displayName: "End Screen",
            type: "end_screen",
            description: "",
            placeholder: "Enter message",
            required: false,
          },
        ],
      };
      if (userType == "Create") {
        const updateData = {
          ...data,
          question: `question_${newFormData.form.length + 1}`,
        };
        const updatedFormData = {
          ...newFormData,
          form: [...newFormData.form, updateData],
        };
        setNewFormData(updatedFormData);
      } else {
        const updateData = {
          ...data,
          question: `question_${updateFormData.form.length + 1}`,
        };
        const updatedFormData: FormData = {
          ...updateFormData,
          form: [...updateFormData.form, updateData],
        };
        setUpdateFormData(updatedFormData);
      }
    } else if (value == 'payment') {
      const data = {
        question: "step",
        conditionRule: [],
        fields: [
          {
            fieldName: "Payment Amount",
            displayName: "Payment Amount",
            type: "payment",
            placeholder: "00.00",
            amount: '',
            description: "",
            required: true,
            validations: [
              {
                min: 7,
                message: "Phone should be greater than 7",
              },
              {
                max: 10,
                message: "Phone should be less than 10",
              },
            ],
          },
        ],
      };
      if (userType == "Create") {
        const updateData = {
          ...data,
          question: `question_${newFormData.form.length + 1}`,
        };
        const updatedFormData = {
          ...newFormData,
          form: [...newFormData.form, updateData],
        };
        setNewFormData(updatedFormData);
      } else {
        const updateData = {
          ...data,
          question: `question_${updateFormData.form.length + 1}`,
        };
        const updatedFormData: FormData = {
          ...updateFormData,
          form: [...updateFormData.form, updateData],
        };
        setUpdateFormData(updatedFormData);
      }
    }
    setActiveField(`question_${newFormData.form.length + 1}`);
    setQuestionBox(false);
  };

  const onCreateNewForm = () => {
    setFormOpen(true);
    // const dummyObject = {
    //     _id:'',
    //     name: "",
    //     description: "",
    //     type: "lead",
    //     form:[],
    //     uniqueCode:''
    //     // createdBy: "Vivek",
    //     // listName: "Custom form",
    // }
    setUserType("Create");
    // setUpdateFormData(dummyObject)
  };

  const handleSubmitForm = async () => {
    try {
      setIsPageLoad(true);
      const endScreen = {
        question: "step",
        fields: [
          {
            fieldName: "end_screen",
            displayName: "End Screen",
            type: "end_screen",
            description: "Thank you for your response. We will be in touch shortly",
            placeholder: "Enter message",
            required: false,
          },
        ],
      };

      if (userType == "Create") {
        const updatedJson = { ...newFormData };
        updatedJson.form.forEach((step: any) => {
          step.fields.forEach((field: any) => {
            field.fieldName = field.fieldName.trim().replace(/ /g, "_");
          });
        });
        const selectOptionField = updatedJson.form.find((step: any) => {
          return step.fields.some((field: any) => field.type === "multiple_options");
        });

        if (selectOptionField) {
          const selectOption = selectOptionField.fields.find(
            (field: any) => field.type === "multiple_options"
          );
          if (selectOption && selectOption.options) {
            selectOption.options = selectOption.options.filter((option: any) => {
              // Trim and replace spaces in the value property
              option.value = option.value.trim().replace(/ /g, "_");

              // Only keep objects where the trimmed and replaced value is not blank
              return option.value !== '';
            });
          }
          // if (selectOption && selectOption.options) {
          //   selectOption.options.forEach((option:any) => {
          //     option.value = option.value.trim().replace(/ /g, "_"); 
          //     option.displayName = option.displayName;
          //   });
          // }
        }

        const hasEndScreen = updatedJson.form.some((step: any) => step.fields.some((field: any) => field.type === "end_screen"));
        const hasEmailNumber = updatedJson.form.some((step: any) =>
          step.fields.some((field: any) => field.type === "email" || field.type === "phone")
        );

        if (!hasEmailNumber) {
          toast.error('Must Include at least one phone or email question')
          setIsPageLoad(false)
          return
        }

        if (!hasEndScreen) {
          updatedJson.form.push({ ...endScreen, question: `question_${updatedJson.form.length + 1}` });
        }
        if (updatedJson.hasOwnProperty("list") && updatedJson.list === "") {
          delete updatedJson.list;
        }


        const response = await createCustomForm(updatedJson);
        if (response && response.status) {
          handleFormList();
          setFullScreenForm(false);
          setNewFormData({
            name: "",
            formType: "lead",
            list: "",
            description: "",
            form: [],
          })
          setFormOpen(false);
        }
      } else {
        const updatedJson = { ...updateFormData };
        updatedJson.form.forEach((step: any) => {
          step.fields.forEach((field: any) => {
            field.fieldName = field.fieldName.trim().replace(/ /g, "_");
          });
        });
        const selectOptionField = updatedJson.form.find((step: any) => {
          return step.fields.some((field: any) => field.type === "multiple_options");
        });

        if (selectOptionField) {
          const selectOption = selectOptionField.fields.find(
            (field: any) => field.type === "multiple_options"
          );
          if (selectOption && selectOption.options) {
            selectOption.options.forEach((option: any) => {
              option.value = option.value.trim().replace(/ /g, "_");
              option.displayName = option.displayName;
            });
          }
        }
        const hasEndScreen = updatedJson.form.some(step => step.fields.some((field: any) => field.type === "end_screen"));
        const hasEmailNumber = updatedJson.form.some((step: any) =>
          step.fields.some((field: any) => field.type === "email" || field.type === "phone")
        );

        if (!hasEmailNumber) {
          toast.error('Must Include at least one phone or email question')
          setIsPageLoad(false)
          return
        }
        if (!hasEndScreen) {
          updatedJson.form.push({ ...endScreen, question: `question_${updatedJson.form.length + 1}` });
        }

        const response = await editCustomForm(
          updateFormData.form,
          activeFormId
        );
        if (response && response.status) {
          handleFormList();
          setFullScreenForm(false);
          setFormOpen(false);
          toast.success(response.data.message);
        }
      }
      setIsPageLoad(false);
    } catch (err) {
      setIsPageLoad(false);
      setFormOpen(false);
      console.log(err, "Error");
    }
  };

  const handleEditFormValue = (
    step: string,
    type: string,
    keyName: string,
    value: string | boolean
  ) => {
    if (userType == "Create") {
      if (type == "multiple_options") {
        // if(type == 'multiple_options' || type =='date'){
        const updatedForm = newFormData.form.map((item: any) => {
          if (item.question === step) {
            const updatedFields = item.fields.map((field: any) => {
              if (field.type === type) {
                if (keyName == "displayName") {
                  return {
                    ...field,
                    fieldName: value,
                    [keyName]: value, // Update the specified field with the new value
                  };
                } else {
                  return {
                    ...field,
                    [keyName]: value, // Update the specified field with the new value
                  };
                }
              }
              return field;
            });
            return {
              ...item,
              fields: updatedFields,
            };
          }
          return item;
        });
        const updatedFormData: FormData = {
          ...newFormData,
          form: updatedForm,
        };
        setNewFormData(updatedFormData);
      } else {
        const updatedForm = newFormData.form.map((item: any) => {
          if (item.question === step) {
            const updatedFields = item.fields.map((field: any) => {
              if (field.type === type) {
                if (keyName == "displayName") {
                  return {
                    ...field,
                    fieldName: value,
                    [keyName]: value, // Update the specified field with the new value
                  };
                } else {
                  return {
                    ...field,
                    [keyName]: value, // Update the specified field with the new value
                  };
                }
              }
              return field;
            });
            return {
              ...item,
              fields: updatedFields,
            };
          }
          return item;
        });
        const updatedFormData: FormData = {
          ...newFormData,
          form: updatedForm,
        };
        setNewFormData(updatedFormData);
      }
    } else {

      if (type == "multiple_options") {
        // if(type == 'multiple_options' || type =='date'){
        const updatedForm = updateFormData.form.map((item: any) => {
          if (item.question === step) {
            const updatedFields = item.fields.map((field: any) => {
              if (field.type === type) {
                if (keyName == "displayName") {
                  return {
                    ...field,
                    fieldName: value,
                    [keyName]: value, // Update the specified field with the new value
                  };
                } else {
                  return {
                    ...field,
                    [keyName]: value, // Update the specified field with the new value
                  };
                }
              }
              return field;
            });
            return {
              ...item,
              fields: updatedFields,
            };
          }
          return item;
        });
        const updatedFormData: FormData = {
          ...updateFormData,
          form: updatedForm,
        };
        setUpdateFormData(updatedFormData);
      } else {
        const updatedForm = updateFormData.form.map((item: any) => {

          if (item.question === step) {
            const updatedFields = item.fields.map((field: any) => {
              if (field.type === type) {
                if (keyName == "fieldName") {
                  return {
                    ...field,
                    placeholder: value,
                    [keyName]: value, // Update the specified field with the new value
                  };
                } else {
                  return {
                    ...field,
                    [keyName]: value, // Update the specified field with the new value
                  };
                }
              }
              return field;
            });
            return {
              ...item,
              fields: updatedFields,
            };
          }
          return item;
        });
        const updatedFormData: FormData = {
          ...updateFormData,
          form: updatedForm,
        };
        setUpdateFormData(updatedFormData);
      }

      //   const updatedForm = updateFormData.form.map((item: any) => {
      //     if (item.question === step) {
      //       const updatedFields = item.fields.map((field: any) => {
      //         if (field.type === type) {
      //           return {
      //             ...field,
      //             [keyName]: value, // Update the specified field with the new value
      //           };
      //         }
      //         return field;
      //       });
      //       return {
      //         ...item,
      //         fields: updatedFields,
      //       };
      //     }
      //     return item;
      //   });
      //   const updatedFormData: FormData = {
      //     ...updateFormData,
      //     form: updatedForm,
      //   };
      //   setUpdateFormData(updatedFormData);
    }
  };

  const handleCloseForm = () => {
    setNewFormData({
      name: "",
      formType: "lead",
      list: "",
      description: "",
      form: [],
    });
  };

  const addOptions = (question: any) => {
    if (userType === 'Create') {
      const newData = newFormData.form.map((i: any) => {
        if (i.question === question) {
          return {
            ...i,
            fields: i.fields.map((j: any) => {
              if (j.type === 'multiple_options' && j.options.length > 0) {
                const lastOption = j.options[j.options.length - 1];
                const seenValues = new Set();

                const checkVal = j.options.some((obj: any) => {
                  const lowercaseValue = obj.value.toLowerCase();
                  if (seenValues.has(lowercaseValue)) {
                    return true;
                  }
                  seenValues.add(lowercaseValue);
                  return false;
                });

                if (lastOption.displayName.trim() === '' || lastOption.value.trim() === '') {
                  toast.error('Please add option')
                  return j;
                }
                else if (checkVal && j.options.length > 1) {
                  toast.error('Please add Different Option')
                  return j;
                }
              }
              return {
                ...j,
                options: [
                  ...j.options,
                  {
                    displayName: `Option_${j.options.length + 1}`,
                    value: ``,
                    // value: `Option ${j.options.length + 1}`,
                  },
                ],
              };
            }),
          };
        }
        return i;
      });
      setNewFormData({ ...newFormData, form: newData });
    } else {
      const newData = updateFormData.form.map((i: any) => {
        if (i.question === question) {
          return {
            ...i,
            fields: i.fields.map((j: any) => {
              if (j.type === 'multiple_options' && j.options.length > 0) {
                const lastOption = j.options[j.options.length - 1];
                if (lastOption.displayName.trim() === '' || lastOption.value.trim() === '') {
                  toast.error('Please add option')
                  return j;
                }
                else if (j.options.every((item: any, index: number, array: any) => item.value.toLowerCase().trim() === array[0].value.toLowerCase().trim()) && j.options.length > 1) {
                  toast.error('Please add Different Option')
                  return j;
                }
              }
              return {
                ...j,
                options: [
                  ...j.options,
                  {
                    displayName: `Option ${j.options.length + 1}`,
                    value: ``,
                    // value: `Option ${j.options.length + 1}`,
                  },
                ],
              };
            }),
          };
        }
        return i;
      });
      setUpdateFormData({ ...updateFormData, form: newData });
    }
  };

  const updateOptions = (
    question: any,
    optionValue: number,
    value: string,
    keyValue: string
  ) => {
    if (userType == "Create") {
      const newData = newFormData.form.map((i: any) => {
        if (i.question === question) {
          return {
            ...i,
            fields: i.fields.map((j: any) => {
              if (j.type === "multiple_options") {
                const newOptions = j.options.map(
                  (option: any, index: number) => {
                    if (index === optionValue) {
                      return {
                        ...option,
                        [keyValue]: value,
                      };
                    }
                    return option;
                  }
                );
                return {
                  ...j,
                  options: newOptions,
                };
              }
              return j;
            }),
          };
        }
        return i;
      });
      setNewFormData({ ...newFormData, form: newData });
    } else {
      const newData = updateFormData.form.map((i: any) => {
        if (i.question === question) {
          return {
            ...i,
            fields: i.fields.map((j: any) => {
              if (j.type === "multiple_options") {
                const newOptions = j.options.map(
                  (option: any, index: number) => {
                    if (index === optionValue) {
                      return {
                        ...option,
                        [keyValue]: value,
                      };
                    }
                    return option;
                  }
                );
                return {
                  ...j,
                  options: newOptions,
                };
              }
              return j;
            }),
          };
        }
        return i;
      });
      setUpdateFormData({ ...updateFormData, form: newData });
    }
  };

  const deleteOptions = (
    question: any,
    optionValue: number,
  ) => {
    const formDataToUpdate = userType === 'Create' ? newFormData : updateFormData;

    const newData = formDataToUpdate.form.map((step: any) => {
      if (step.question === question) {
        return {
          ...step,
          fields: step.fields.map((field: any) => {
            if (field.type === 'multiple_options') {
              const newOptions = field.options.filter(
                (option: any, index: number) => index !== optionValue
              ).map((option: any, index: number) => {
                return {
                  ...option,
                  displayName: `Option ${index + 1}`,
                };
              });
              return {
                ...field,
                options: newOptions,
              };
            }
            return field;
          }),
        };
      }
      return step;
    });

    if (userType === 'Create') {
      setNewFormData({ ...newFormData, form: newData });
    } else {
      setUpdateFormData({ ...updateFormData, form: newData });
    }
  };

  const deleteUser = (id: string) => {
    const data = userList.filter((item: any) => item._id !== id);
    setUserList(data);
    setTotalUser(totalUser - 1)
    setOpenWithHeader(false);
  };

  const deleteField = () => {
    const step = activeQuestion
    if (userType == "Create") {
      let updatedForm = newFormData.form.filter(
        (item: any) => item.question !== step
      );
      updatedForm = updatedForm.map((item: any, index: number) => {
        const newQuestionNumber = `question_${index + 1}`;
        return { ...item, question: newQuestionNumber };
      });
      setNewFormData({ ...newFormData, form: updatedForm });
    } else {
      let updatedForm = updateFormData.form.filter(
        (item: any) => item.question !== step
      );
      updatedForm = updatedForm.map((item: any, index: number) => {
        const newQuestionNumber = `question_${index + 1}`;
        return { ...item, question: newQuestionNumber };
      });
      setUpdateFormData({ ...updateFormData, form: updatedForm });
    }
    setFieldModal(false)
  };

  const addCondition = (i: any) => {
    const formData = userType === "Create" ? newFormData : updateFormData;
    const updatedForm = formData.form.map((item: any) =>
      item.question === i.question
        ? {
            ...item,
            conditionRule: item.conditionRule
              ? [...item.conditionRule, { if: "is", value: item.fields[0]?.type === "multiple_options" ? item.fields[0]?.options[0]?.value : "", then_go_to: "question_1" }]
              : [{ if: "is", value: item.fields[0]?.type === "multiple_options" ? item.fields[0]?.options[0]?.value : "", then_go_to: "question_1" }],
          }
        : item
    );
  
    userType === "Create" ? setNewFormData({ ...newFormData, form: updatedForm }) : setUpdateFormData({ ...updateFormData, form: updatedForm });
  };

  const updateCondition = (
    i: any,
    key: string,
    value: string,
    indexValue: number
  ) => {
    if (userType == "Create") {
      const updatedForm = newFormData.form.map((item: any) => {
        if (item.question === i.question) {
          const updatedFields = item.conditionRule.map(
            (field: any, index: number) => {
              if (index === indexValue) {
                return {
                  ...field,
                  [key]: value, // Update the specified field with the new value
                };
              }
              return field;
            }
          );
          return {
            ...item,
            conditionRule: updatedFields,
          };
        }
        return item;
      });
      setNewFormData({ ...newFormData, form: updatedForm });
    } else {
      const updatedForm = updateFormData.form.map((item: any) => {
        if (item.question === i.question) {
          const updatedFields = item.conditionRule.map(
            (field: any, index: number) => {
              if (index === indexValue) {
                return {
                  ...field,
                  [key]: value, // Update the specified field with the new value
                };
              }
              return field;
            }
          );
          return {
            ...item,
            conditionRule: updatedFields,
          };
        }
        return item;
      });
      setUpdateFormData({ ...updateFormData, form: updatedForm });
    }
  };

  const handleDeleteCondition = (question: string, index: number) => {
    if (userType == "Create") {
      const updatedForm = newFormData.form.map((formItem: any) => {
        if (formItem.question === question) {
          // Check if the conditionRule exists and has the specified index
          if (formItem.conditionRule && formItem.conditionRule.length > index) {
            // Create a new conditionRule array without the specified index
            formItem.conditionRule.splice(index, 1);
          }
        }
        return formItem;
      });
      setNewFormData({ ...newFormData, form: updatedForm })
    } else {
      const updatedForm = updateFormData.form.map((formItem: any) => {
        if (formItem.question === question) {
          if (formItem.conditionRule && formItem.conditionRule.length > index) {
            // Create a new conditionRule array without the specified index
            formItem.conditionRule.splice(index, 1);
          }
        }
        return formItem;
      });
      setUpdateFormData({ ...updateFormData, form: updatedForm })
    }


  }

  const getformLeadList = async () => {
    try {
      const id = ''
      const resp = await formLeadList(id)
      if (resp.data.statusCode == 200) {

        let array = resp.data.data
        let newObject = {
          name: "Default lead list",
          _id: ''
        };
        array.unshift(newObject);

        setLeadList(array)
      }
    } catch (err) {
      toast.error('Something went wrong')
    }
  }


  useEffect(() => {
    handleFormList();
    getformLeadList();
    const userDataString = localStorage.getItem('userData');
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      setUserName(userData.firstName)
    }
  }, []);


  const extractNumber = (val: string) => {
    const newVal = val.split("_");
    return newVal[1];
  };

  return (
    <Root id="main" className="main">
      <Header />
      <Spin size="large" tip="Loading..." spinning={isPageLoad}>
        <div className="nt-2 mt-3">
          <div className="followups-section-1 Quotation">
            <div className="d-flex justify-content-between">
              <div className="align-items-center auto-section-1-sub-1">
                <label>Forms({totalUser})</label>
              </div>
              <div className="dropdown d-flex align-items-center">
                <div
                  className="dropdown ms-3"
                  style={{ backgroundColor: "#EBF0F4", borderRadius: "5px" }}
                >
                  <button
                    className="btn fw-bold auto_btn w-100"
                    style={{ background: "#3FAEFD" }}
                    type="button"
                    onClick={() => onCreateNewForm()}
                  >
                    <i className="bi bi-plus-lg"></i> Create new form{" "}
                  </button>
                </div>
              </div>
            </div>
            <table className="table table-borderless">
              <thead style={{ boxShadow: "none", background: "#ffffffa5" }}>
                <tr>
                  <th scope="col">Form Name </th>
                  <th scope="col">Last reponse</th>
                  <th scope="col">Responses</th>
                  <th scope="col">List name</th>
                </tr>
              </thead>
              <tbody>
                {userList.map((item: any, index: number) => {
                  return (
                    <tr
                      style={{ boxShadow: "none" }}
                      className="px-2"
                      onClick={() => {
                        handleClickUser(item._id);
                      }}
                      key={index}
                    >
                      <td scope="row" data-label="Form Name">
                        {item.name}
                      </td>
                      <td data-label="Last reponse">{
                        item.lastResponseLeadName === null
                          ? 'no response'
                          : item.lastResponseLeadName.length === 0
                            ? 'no name'
                            : item.lastResponseLeadName
                      }
                      </td>
                      <td data-label="Responses">{item.totalResponse}</td>
                      <td data-label="List name">
                        {item.listName || "Default Lead List"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

          </div>
        </div>
      </Spin>
      <DrawerComponent
        openWithHeader={openWithHeader}
        setOpenWithHeader={setOpenWithHeader}
        drawerTitle="Form"
        size="full"
      >
        <UserForm
          formDetail={updateFormData}
          onhandleEdit={() => {
            setFullScreenForm(true);
            setUserType("Update");
          }}
          handleActiveForm={(data) => { }}
          handleFormDelete={(id) => {
            deleteUser(id);
          }}
        />
      </DrawerComponent>
      {/* Dialog box for fill form  */}
      <DialogBox
        boxSize="md"
        openDialog={formOpen}
        onClickCloseDialog={() => { setFormCloseModel(true) }}
      >
        <div id="create_form">
          <div className="form-group">
            <div className="top_bar">
              <label
                htmlFor="lead-date"
                className="form-label col-md-6 fs-20 fw-bold"
              >
                Create new Form
              </label>
              <img
                src="assets/img/cancleRed.png"
                className="cursor-pointer col-md-6 w-fit-content"
                onClick={() => {
                  handleCloseForm();
                  setFormOpen(false);
                }}
              />
            </div>
            <Inputs
              name="test"
              type="text"
              autoComplete="off"
              className="form-control"
              placeholder={`Form name`}
              value={newFormData.name}
              onChange={(e: any) => updateCreateForm("name", e.target.value)}
              label="Form name"
              isRequired
              options='{item.type === "selection" && item.options}'
              id="testid"
            />
            <label htmlFor="lead-date" className="form-label mt-2">
              Save form responses to
            </label>
            <select className="select_lead_list" onChange={(e) => { updateCreateForm('list', e.target.value) }}>
              {leadList.map((item: any, ixx: number) => {
                return (<option value={item._id} key={ixx}>{item.name}</option>)
              })}
            </select>
            <Inputs
              name="test"
              type="text"
              autoComplete="off"
              className="form-control note_area"
              placeholder={`Description`}
              value={newFormData.description}
              onChange={(e: any) =>
                updateCreateForm("description", e.target.value)
              }
              label="Description"
              isRequired={false}
              options='{item.type === "selection" && item.options}'
              id="testid"
            />
          </div>
          <button
            className="mt-3 float-right btn fw-bold auto_btn"
            style={{ float: 'right' }}
            onClick={() => {
              handleCreateForm();
            }}
          >
            Continue
          </button>
        </div>
      </DialogBox>

      <DialogBox
        boxSize="full"
        openDialog={fullScreenForm}
        onClickCloseDialog={(e) => { setStepFormModel(true) }}
      >
        <Spin size="large" tip="Loading..." spinning={isPageLoad}>
          <div>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <h4
                  className="btn"
                  style={{ fontSize: "24px", padding: "0px" }}
                >
                  {userType == "Create"
                    ? newFormData.name
                    : updateFormData.name}
                </h4>
                {userType == "Create" ? (
                  <p>
                    Created by {userName} on{" "}
                    {moment(newFormData.createdAt).format("Do MMM YYYY")} at{" "}
                    {moment(newFormData.createdAt).format("hh:mm A")}
                  </p>
                ) : (
                  <p>
                    Created by {updateFormData.createdBy} on{" "}
                    {moment(updateFormData.createdAt).format("Do MMM YYYY")} at{" "}
                    {moment(updateFormData.createdAt).format("hh:mm A")}
                  </p>
                )}

                {/* <p>Created by Amit on 23 Nov 2023</p> */}
              </div>
              <div className="col-md-6 col-sm-12">
                <ul
                  className="nav mt-2 justify-content-end d-flex"
                  style={{ gap: "10px", padding: '10px' }}
                >
                  {/* {userType == "Create" ? (
                    ""
                  ) : (
                    <button className="ml-3 cursor btn btn-light border rounded">
                      <img
                        alt="img"
                        src="assets/img/preview.png"
                        title="Preview"
                        className="cursor-pointer "
                      />{" "}
                      Preview
                    </button>
                  )}
                  {userType == "Create" ? (
                    ""
                  ) : (
                    <button className="ml-3 cursor btn btn-light border rounded">
                      <img
                        alt="img"
                        src="assets/img/world.png"
                        title="Publish"
                        className="cursor-pointer "
                      />{" "}
                      Publish
                    </button>
                  )} */}
                </ul>
                {userType == "Create" ? (
                  ""
                ) : (
                  // <p className="justify-content-end d-flex mt-1 ">{newFormData.status == 'draft' ? 'In Draft' : `Published on  ${moment(newFormData.publishedLog?.publishedAt).format("Do MMM YYYY")} at ${moment(newFormData.publishedLog?.publishedAt).format("hh:mm A")}`} </p>
                  <p className="justify-content-end d-flex mt-1 ">
                    {newFormData.status == "draft"
                      ? "In Draft"
                      : `Published on  ${moment(
                        newFormData.publishedLog?.publishedAt
                      ).format("Do MMM YYYY")} at ${moment(
                        newFormData.publishedLog?.publishedAt
                      ).format("hh:mm A")}`}{" "}
                  </p>
                )}
              </div>
            </div>
            <h6
              className="text-dark mb-3"
              style={{ cursor: "pointer", width: "fit-content" }}
              onClick={() => {
                setQuestionBox(true);
              }}
            >
              Questions{" "}
              <span
                className="bg-primary rounded-circle d-inline-flex align-items-center justify-content-center text-white opacity-75"
                style={{ width: "22px", height: "22px", paddingBottom: "4px" }}
              >
                +
              </span>
            </h6>
            <Row>
              <Col md={4} sm={12}>
                <div className="content_box question_list">
                  {userType == "Create"
                    ? newFormData.form?.map((item: any, index: number) => {
                      return (
                        <button
                          key={index}
                          className={
                            activeField == item.question ? "active" : ""
                          }
                          onClick={() => {
                            setActiveField(item.question);
                          }}
                        >
                          <span>{index + 1}</span>
                          <p>{item.fields[0].displayName}
                            {item.fields[0].required && ' *'}</p>
                        </button>
                      );
                    })
                    : updateFormData.form?.map((item: any, index: number) => {
                      return (
                        <button
                          key={index}
                          className={
                            activeField == item.question ? "active" : ""
                          }
                          onClick={() => {
                            setActiveField(item.question);
                          }}
                        >
                          <span>{index + 1}</span>
                          <p>{item.fields[0].displayName}
                            {item.fields[0].required && ' *'}</p>
                        </button>
                      );
                    })}
                </div>
              </Col>
              <Col md={15} sm={12}>
                <div className="content_box d-flex">
                  {userType == "Create"
                    ? newFormData.form
                      ?.filter((item: any) => {
                        return item.question === activeField;
                      })
                      .map((i: any, ix: number) => {
                        return i.fields[0].type == "short_text" ? (
                          <InputStyledComp key={ix}>
                            <div className="field_name sr_number">
                              <span>
                                {extractNumber(i.question)}{" "}
                                <FontAwesomeIcon icon={faArrowRight} />
                              </span>
                              <input
                                type="text"
                                value={i.fields[0].displayName}
                                placeholder={"Display Name"}
                                onChange={(e) => {
                                  handleEditFormValue(
                                    i.question,
                                    i.fields[0].type,
                                    "displayName",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                            <input
                              type="text"
                              value={i.fields[0].description}
                              className="discription"
                              placeholder="Discription (optional)"
                              onChange={(e) => {
                                handleEditFormValue(
                                  i.question,
                                  i.fields[0].type,
                                  "description",
                                  e.target.value
                                );
                              }}
                            />
                            <input
                              type="text"
                              className="input_box"
                              placeholder={i.fields[0].placeholder}
                            // value={i.fields[0].fieldName.replace(/_/g, " ")}
                            // onChange={(e) => {
                            //   handleEditFormValue(
                            //     i.question,
                            //     i.fields[0].type,
                            //     "fieldName",
                            //     e.target.value
                            //   );
                            // }}
                            />
                            <button
                              className="delete_btn"
                              onClick={() => {
                                setActiveQuestion(i.question);
                                setFieldModal(true)
                              }}
                            >
                              <img src="assets/img/delete.png" />
                            </button>
                          </InputStyledComp>
                        ) : i.fields[0].type == "number" ? (
                          <InputStyledComp key={ix}>
                            <div className="field_name sr_number">
                              <span>
                                {extractNumber(i.question)}{" "}
                                <FontAwesomeIcon icon={faArrowRight} />
                              </span>
                              <input
                                type="text"
                                value={i.fields[0].displayName}
                                placeholder={"Display Name"}
                                onChange={(e) => {
                                  handleEditFormValue(
                                    i.question,
                                    i.fields[0].type,
                                    "displayName",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                            <input
                              type="text"
                              value={i.fields[0].description}
                              className="discription"
                              placeholder="Discription (optional)"
                              onChange={(e) => {
                                handleEditFormValue(
                                  i.question,
                                  i.fields[0].type,
                                  "description",
                                  e.target.value
                                );
                              }}
                            />
                            <input
                              type="text"
                              className="input_box"
                              placeholder={i.fields[0].placeholder}
                            // placeholder="Type your answer here"
                            // value={i.fields[0].fieldName.replace(/_/g, " ")}
                            // onChange={(e) => {
                            //   handleEditFormValue(
                            //     i.question,
                            //     i.fields[0].type,
                            //     "fieldName",
                            //     e.target.value
                            //   );
                            // }}
                            />
                            <button
                              className="delete_btn"
                              onClick={() => {
                                setActiveQuestion(i.question);
                                setFieldModal(true)
                              }}
                            >
                              <img src="assets/img/delete.png" />
                            </button>
                          </InputStyledComp>
                        ) : i.fields[0].type == "phone" ? (
                          <InputStyledComp key={ix}>
                            <div className="field_name sr_number">
                              <span>
                                {extractNumber(i.question)}{" "}
                                <FontAwesomeIcon icon={faArrowRight} />
                              </span>
                              <input
                                type="text"
                                placeholder={"Display Name"}
                                value={i.fields[0].displayName}
                                onChange={(e) => {
                                  handleEditFormValue(
                                    i.question,
                                    i.fields[0].type,
                                    "displayName",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                            <input
                              type="text"
                              value={i.fields[0].description}
                              className="discription"
                              placeholder="Discription (optional)"
                              onChange={(e) => {
                                handleEditFormValue(
                                  i.question,
                                  i.fields[0].type,
                                  "description",
                                  e.target.value
                                );
                              }}
                            />

                            <input
                              type="text"
                              className="input_box"
                              placeholder={i.fields[0].placeholder}
                            // placeholder="Enter the field name"
                            // value={i.fields[0].fieldName.replace(/_/g, " ")}
                            // onChange={(e) => {
                            //   handleEditFormValue(
                            //     i.question,
                            //     i.fields[0].type,
                            //     "fieldName",
                            //     e.target.value
                            //   );
                            // }}
                            />
                            <button
                              className="delete_btn"
                              onClick={() => {
                                setActiveQuestion(i.question);
                                setFieldModal(true)
                              }}
                            >
                              <img src="assets/img/delete.png" />
                            </button>
                          </InputStyledComp>
                        ) : i.fields[0].type == "email" ? (
                          <InputStyledComp key={ix}>
                            <div className="field_name sr_number">
                              <span>
                                {extractNumber(i.question)}{" "}
                                <FontAwesomeIcon icon={faArrowRight} />
                              </span>
                              <input
                                type="text"
                                placeholder={"Display Name"}
                                value={i.fields[0].displayName.replace(
                                  /_/g,
                                  " "
                                )}
                                onChange={(e) => {
                                  handleEditFormValue(
                                    i.question,
                                    i.fields[0].type,
                                    "displayName",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                            <input
                              type="text"
                              value={i.fields[0].description}
                              className="discription"
                              placeholder="Discription (optional)"
                              onChange={(e) => {
                                handleEditFormValue(
                                  i.question,
                                  i.fields[0].type,
                                  "description",
                                  e.target.value
                                );
                              }}
                            />
                            <input
                              type="email"
                              className="input_box"
                              placeholder={i.fields[0].placeholder}
                            // placeholder="Type your answer here"
                            // value={i.fields[0].fieldName}
                            // onChange={(e) => {
                            //   handleEditFormValue(
                            //     i.question,
                            //     i.fields[0].type,
                            //     "fieldName",
                            //     e.target.value
                            //   );
                            // }}
                            />
                            <button
                              className="delete_btn"
                              onClick={() => {
                                setActiveQuestion(i.question);
                                setFieldModal(true)
                              }}
                            >
                              <img src="assets/img/delete.png" />
                            </button>
                          </InputStyledComp>
                        ) : i.fields[0].type == "file_upload" ? (
                          <InputStyledComp key={ix}>
                            <div className="field_name sr_number">
                              <span>
                                {extractNumber(i.question)}{" "}
                                <FontAwesomeIcon icon={faArrowRight} />
                              </span>
                              <input
                                type="text"
                                placeholder={"Display Name"}
                                value={i.fields[0].displayName}
                                onChange={(e) => {
                                  handleEditFormValue(
                                    i.question,
                                    i.fields[0].type,
                                    "displayName",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                            <input
                              type="text"
                              value={i.fields[0].description}
                              className="discription"
                              placeholder="Discription (optional)"
                              onChange={(e) => {
                                handleEditFormValue(
                                  i.question,
                                  i.fields[0].type,
                                  "description",
                                  e.target.value
                                );
                              }}
                            />
                            <div className="dragggable_file">
                              <span>
                                <FontAwesomeIcon icon={faUpload} /> Choose
                                file or drag file here{" "}
                              </span>
                              <input type="file" />
                            </div>
                            <button
                              className="delete_btn"
                              onClick={() => {
                                setActiveQuestion(i.question);
                                setFieldModal(true)
                              }}
                            >
                              <img src="assets/img/delete.png" />
                            </button>
                          </InputStyledComp>
                        ) : i.fields[0].type == "long_text" ? (
                          <InputStyledComp key={ix}>
                            <div className="field_name sr_number">
                              <span>
                                {extractNumber(i.question)}{" "}
                                <FontAwesomeIcon icon={faArrowRight} />
                              </span>
                              <input
                                type="text"
                                placeholder={"Display Name"}
                                value={i.fields[0].displayName}
                                onChange={(e) => {
                                  handleEditFormValue(
                                    i.question,
                                    i.fields[0].type,
                                    "displayName",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                            <input
                              type="text"
                              value={i.fields[0].description}
                              className="discription"
                              placeholder="Discription (optional)"
                              onChange={(e) => {
                                handleEditFormValue(
                                  i.question,
                                  i.fields[0].type,
                                  "description",
                                  e.target.value
                                );
                              }}
                            />
                            <textarea
                              rows={5} // Specify the number of visible rows
                              cols={50} // Specify the number of visible columns
                              placeholder={i.fields[0].placeholder}
                            // value={i.fields[0].fieldName}
                            // onChange={(e) => {
                            //   handleEditFormValue(
                            //     i.question,
                            //     i.fields[0].type,
                            //     "fieldName",
                            //     e.target.value
                            //   );
                            // }}
                            />
                            <button
                              className="delete_btn"
                              onClick={() => {
                                setActiveQuestion(i.question);
                                setFieldModal(true)
                              }}
                            >
                              <img src="assets/img/delete.png" />
                            </button>
                          </InputStyledComp>
                        ) : i.fields[0].type == "date" ? (
                          <InputStyledComp key={ix}>
                            <div className="field_name sr_number">
                              <span>
                                {extractNumber(i.question)}{" "}
                                <FontAwesomeIcon icon={faArrowRight} />
                              </span>
                              <input
                                type="text"
                                placeholder={"Display Name"}
                                value={i.fields[0].displayName}
                                onChange={(e) => {
                                  handleEditFormValue(
                                    i.question,
                                    i.fields[0].type,
                                    "displayName",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                            <input
                              type="text"
                              value={i.fields[0].description}
                              className="discription"
                              placeholder="Discription (optional)"
                              onChange={(e) => {
                                handleEditFormValue(
                                  i.question,
                                  i.fields[0].type,
                                  "description",
                                  e.target.value
                                );
                              }}
                            />
                            {/* <input type="date" name="datePick" className='input_box' placeholder='check' value={i.fields[0].fieldName} onChange={(e) => { handleEditFormValue(i.question, i.fields[0].type, 'fieldName', e.target.value) }} /> */}
                            <input
                              type="text"
                              className="input_box"
                              placeholder={i.fields[0].placeholder}
                            // placeholder="Enter the field name"
                            // value={i.fields[0].fieldName}
                            // onChange={(e) => {
                            //   handleEditFormValue(
                            //     i.question,
                            //     i.fields[0].type,
                            //     "fieldName",
                            //     e.target.value
                            //   );
                            // }}
                            />
                            <button
                              className="delete_btn"
                              onClick={() => {
                                setActiveQuestion(i.question);
                                setFieldModal(true)
                              }}
                            >
                              <img src="assets/img/delete.png" />
                            </button>
                          </InputStyledComp>
                        ) : i.fields[0].type == "address" ? (
                          <InputStyledComp key={ix}>
                            <div className="field_name sr_number">
                              <span>
                                {extractNumber(i.question)}{" "}
                                <FontAwesomeIcon icon={faArrowRight} />
                              </span>
                              <input
                                type="text"
                                placeholder={"Display Name"}
                                value={i.fields[0].displayName}
                                onChange={(e) => {
                                  handleEditFormValue(
                                    i.question,
                                    i.fields[0].type,
                                    "displayName",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                            <input
                              type="text"
                              value={i.fields[0].description}
                              className="discription"
                              placeholder="Discription (optional)"
                              onChange={(e) => {
                                handleEditFormValue(
                                  i.question,
                                  i.fields[0].type,
                                  "description",
                                  e.target.value
                                );
                              }}
                            />
                            <textarea
                              rows={4} // Specify the number of visible rows
                              cols={50} // Specify the number of visible columns
                              placeholder="Enter your multiline text here..."
                              value={i.fields[0].fieldName}
                              onChange={(e) => {
                                handleEditFormValue(
                                  i.question,
                                  i.fields[0].type,
                                  "fieldName",
                                  e.target.value
                                );
                              }}
                            />
                            <button
                              className="delete_btn"
                              onClick={() => {
                                setActiveQuestion(i.question);
                                setFieldModal(true)
                              }}
                            >
                              <img src="assets/img/delete.png" />
                            </button>
                          </InputStyledComp>
                        ) : i.fields[0].type == "end_screen" ? (
                          <InputStyledComp key={ix}>
                            <input
                              type="text"
                              className="input_box"
                              placeholder={i.fields[0].placeholder}
                              // placeholder={"End screen"}
                              value={i.fields[0].description}
                              onChange={(e) => {
                                handleEditFormValue(
                                  i.question,
                                  i.fields[0].type,
                                  "description",
                                  e.target.value
                                );
                              }}
                            />
                            <button
                              className="delete_btn"
                              onClick={() => {
                                setActiveQuestion(i.question);
                                setFieldModal(true)
                              }}
                            >
                              <img src="assets/img/delete.png" />
                            </button>
                          </InputStyledComp>
                        ) : i.fields[0].type == "multiple_options" ? (
                          <MultipleOption key={ix}>
                            <div className="field_name sr_number">
                              <span>
                                {extractNumber(i.question)}{" "}
                                <FontAwesomeIcon icon={faArrowRight} />
                              </span>
                              <input
                                type="text"
                                placeholder={"Display Name"}
                                value={i.fields[0].displayName}
                                onChange={(e) => {
                                  handleEditFormValue(
                                    i.question,
                                    i.fields[0].type,
                                    "displayName",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                            <input
                              type="text"
                              value={i.fields[0].description}
                              className="discription"
                              placeholder="Discription (optional)"
                              onChange={(e) => {
                                handleEditFormValue(
                                  i.question,
                                  i.fields[0].type,
                                  "description",
                                  e.target.value
                                );
                              }}
                            />
                            <div className="mt-3 multi_option_btn">
                              {i.fields[0].options.map(
                                (j: any, jx: number) => {
                                  return (
                                    <div className="option_box_field" key={jx}>
                                      <span>{jx + 1}</span>
                                      <input
                                        type="text"
                                        placeholder="Enter Option"
                                        value={j.value.replace(/_/g, " ")}
                                        onChange={(e) => {
                                          updateOptions(
                                            i.question,
                                            jx,
                                            e.currentTarget.value,
                                            "value"
                                          );
                                        }}
                                      />
                                      <button className="delete_option" onClick={() => { deleteOptions(i.question, jx) }}><img src="assets/img/delete.png" /></button>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                            <button
                              className="option_btn"
                              onClick={() => {
                                addOptions(i.question);
                              }}
                            >
                              Add Option
                            </button>
                            <button
                              className="delete_btn"
                              onClick={() => {
                                setActiveQuestion(i.question);
                                setFieldModal(true)
                              }}
                            >
                              <img src="assets/img/delete.png" />
                            </button>
                          </MultipleOption>
                        ) : i.fields[0].type == "payment" ? (
                          <PaymentFiels key={ix}>
                            <div className="field_name sr_number">
                              <span>
                                {extractNumber(i.question)}{" "}
                                <FontAwesomeIcon icon={faArrowRight} />
                              </span>
                              <input
                                type="text"
                                value={i.fields[0].displayName}
                                placeholder={"Display Name"}
                                onChange={(e) => {
                                  handleEditFormValue(
                                    i.question,
                                    i.fields[0].type,
                                    "displayName",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                            <input
                              type="text"
                              value={i.fields[0].description}
                              className="discription"
                              placeholder="Discription (optional)"
                              onChange={(e) => {
                                handleEditFormValue(
                                  i.question,
                                  i.fields[0].type,
                                  "description",
                                  e.target.value
                                );
                              }}
                            />
                            <input
                              type="number"
                              className="input_box"
                              placeholder={i.fields[0].placeholder}
                              value={i.fields[0].amount}
                              onChange={(e) => {
                                handleEditFormValue(
                                  i.question,
                                  i.fields[0].type,
                                  "amount",
                                  e.target.value
                                );
                              }}
                            />
                            <button
                              className="delete_btn"
                              onClick={() => {
                                setActiveQuestion(i.question);
                                setFieldModal(true)
                              }}
                            >
                              <img src="assets/img/delete.png" />
                            </button>
                          </PaymentFiels>
                        ) : (
                          <div className="content_box">
                            <h2>Please create a question to continue</h2>
                          </div>
                        );
                      })
                    : updateFormData.form
                      ?.filter((item: any) => {
                        return item.question === activeField;
                      })
                      .map((i: any, ix: number) => {
                        return i.fields[0].type == "short_text" ? (
                          <InputStyledComp key={ix}>
                            <div className="field_name sr_number">
                              <span>
                                {extractNumber(i.question)}{" "}
                                <FontAwesomeIcon icon={faArrowRight} />
                              </span>
                              <input
                                type="text"
                                value={i.fields[0].displayName}
                                placeholder={"Display Name"}
                                onChange={(e) => {
                                  handleEditFormValue(
                                    i.question,
                                    i.fields[0].type,
                                    "displayName",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                            <input
                              type="text"
                              value={i.fields[0].description}
                              className="discription"
                              placeholder="Discription (optional)"
                              onChange={(e) => {
                                handleEditFormValue(
                                  i.question,
                                  i.fields[0].type,
                                  "description",
                                  e.target.value
                                );
                              }}
                            />
                            <input
                              type="text"
                              className="input_box"
                              placeholder={i.fields[0].placeholder}
                            // value={i.fields[0].fieldName.replace(/_/g, " ")}
                            // onChange={(e) => {
                            //   handleEditFormValue(
                            //     i.question,
                            //     i.fields[0].type,
                            //     "fieldName",
                            //     e.target.value
                            //   );
                            // }}
                            />
                            <button
                              className="delete_btn"
                              onClick={() => {
                                setActiveQuestion(i.question);
                                setFieldModal(true)
                              }}
                            >
                              <img src="assets/img/delete.png" />
                            </button>
                          </InputStyledComp>
                        ) : i.fields[0].type == "number" ? (
                          <InputStyledComp key={ix}>
                            <div className="field_name sr_number">
                              <span>
                                {extractNumber(i.question)}{" "}
                                <FontAwesomeIcon icon={faArrowRight} />
                              </span>
                              <input
                                type="text"
                                value={i.fields[0].displayName}
                                placeholder={"Display Name"}
                                onChange={(e) => {
                                  handleEditFormValue(
                                    i.question,
                                    i.fields[0].type,
                                    "displayName",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                            <input
                              type="text"
                              value={i.fields[0].description}
                              className="discription"
                              placeholder="Discription (optional)"
                              onChange={(e) => {
                                handleEditFormValue(
                                  i.question,
                                  i.fields[0].type,
                                  "description",
                                  e.target.value
                                );
                              }}
                            />
                            <input
                              type="text"
                              className="input_box"
                              placeholder={i.fields[0].placeholder}
                            // placeholder="Type your answer here"
                            // value={i.fields[0].fieldName.replace(/_/g, " ")}
                            // onChange={(e) => {
                            //   handleEditFormValue(
                            //     i.question,
                            //     i.fields[0].type,
                            //     "fieldName",
                            //     e.target.value
                            //   );
                            // }}
                            />
                            <button
                              className="delete_btn"
                              onClick={() => {
                                setActiveQuestion(i.question);
                                setFieldModal(true)
                              }}
                            >
                              <img src="assets/img/delete.png" />
                            </button>
                          </InputStyledComp>
                        ) : i.fields[0].type == "phone" ? (
                          <InputStyledComp key={ix}>
                            <div className="field_name sr_number">
                              <span>
                                {extractNumber(i.question)}{" "}
                                <FontAwesomeIcon icon={faArrowRight} />
                              </span>
                              <input
                                type="text"
                                placeholder={"Display Name"}
                                value={i.fields[0].displayName}
                                onChange={(e) => {
                                  handleEditFormValue(
                                    i.question,
                                    i.fields[0].type,
                                    "displayName",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                            <input
                              type="text"
                              value={i.fields[0].description}
                              className="discription"
                              placeholder="Discription (optional)"
                              onChange={(e) => {
                                handleEditFormValue(
                                  i.question,
                                  i.fields[0].type,
                                  "description",
                                  e.target.value
                                );
                              }}
                            />

                            <input
                              type="text"
                              className="input_box"
                              placeholder={i.fields[0].placeholder}
                            // placeholder="Enter the field name"
                            // value={i.fields[0].fieldName.replace(/_/g, " ")}
                            // onChange={(e) => {
                            //   handleEditFormValue(
                            //     i.question,
                            //     i.fields[0].type,
                            //     "fieldName",
                            //     e.target.value
                            //   );
                            // }}
                            />
                            <button
                              className="delete_btn"
                              onClick={() => {
                                setActiveQuestion(i.question);
                                setFieldModal(true)
                              }}
                            >
                              <img src="assets/img/delete.png" />
                            </button>
                          </InputStyledComp>
                        ) : i.fields[0].type == "email" ? (
                          <InputStyledComp key={ix}>
                            <div className="field_name sr_number">
                              <span>
                                {extractNumber(i.question)}{" "}
                                <FontAwesomeIcon icon={faArrowRight} />
                              </span>
                              <input
                                type="text"
                                placeholder={"Display Name"}
                                value={i.fields[0].displayName.replace(
                                  /_/g,
                                  " "
                                )}
                                onChange={(e) => {
                                  handleEditFormValue(
                                    i.question,
                                    i.fields[0].type,
                                    "displayName",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                            <input
                              type="text"
                              value={i.fields[0].description}
                              className="discription"
                              placeholder="Discription (optional)"
                              onChange={(e) => {
                                handleEditFormValue(
                                  i.question,
                                  i.fields[0].type,
                                  "description",
                                  e.target.value
                                );
                              }}
                            />
                            <input
                              type="email"
                              className="input_box"
                              placeholder={i.fields[0].placeholder}
                            // placeholder="Type your answer here"
                            // value={i.fields[0].fieldName}
                            // onChange={(e) => {
                            //   handleEditFormValue(
                            //     i.question,
                            //     i.fields[0].type,
                            //     "fieldName",
                            //     e.target.value
                            //   );
                            // }}
                            />
                            <button
                              className="delete_btn"
                              onClick={() => {
                                setActiveQuestion(i.question);
                                setFieldModal(true)
                              }}
                            >
                              <img src="assets/img/delete.png" />
                            </button>
                          </InputStyledComp>
                        ) : i.fields[0].type == "file_upload" ? (
                          <InputStyledComp key={ix} >
                            <div className="field_name sr_number">
                              <span>
                                {extractNumber(i.question)}{" "}
                                <FontAwesomeIcon icon={faArrowRight} />
                              </span>
                              <input
                                type="text"
                                placeholder={"Display Name"}
                                value={i.fields[0].displayName}
                                onChange={(e) => {
                                  handleEditFormValue(
                                    i.question,
                                    i.fields[0].type,
                                    "displayName",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                            <input
                              type="text"
                              value={i.fields[0].description}
                              className="discription"
                              placeholder="Discription (optional)"
                              onChange={(e) => {
                                handleEditFormValue(
                                  i.question,
                                  i.fields[0].type,
                                  "description",
                                  e.target.value
                                );
                              }}
                            />
                            <div className="dragggable_file">
                              <span>
                                <FontAwesomeIcon icon={faUpload} /> Choose
                                file or drag file here{" "}
                              </span>
                              <input type="file" />
                            </div>
                            <button
                              className="delete_btn"
                              onClick={() => {
                                setActiveQuestion(i.question);
                                setFieldModal(true)
                              }}
                            >
                              <img src="assets/img/delete.png" />
                            </button>
                          </InputStyledComp>
                        ) : i.fields[0].type == "long_text" ? (
                          <InputStyledComp key={ix}>
                            <div className="field_name sr_number">
                              <span>
                                {extractNumber(i.question)}{" "}
                                <FontAwesomeIcon icon={faArrowRight} />
                              </span>
                              <input
                                type="text"
                                placeholder={"Display Name"}
                                value={i.fields[0].displayName}
                                onChange={(e) => {
                                  handleEditFormValue(
                                    i.question,
                                    i.fields[0].type,
                                    "displayName",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                            <input
                              type="text"
                              value={i.fields[0].description}
                              className="discription"
                              placeholder="Discription (optional)"
                              onChange={(e) => {
                                handleEditFormValue(
                                  i.question,
                                  i.fields[0].type,
                                  "description",
                                  e.target.value
                                );
                              }}
                            />
                            <textarea
                              rows={5} // Specify the number of visible rows
                              cols={50} // Specify the number of visible columns
                              placeholder="Enter your multiline text here..."
                              value={i.fields[0].fieldName}
                              onChange={(e) => {
                                handleEditFormValue(
                                  i.question,
                                  i.fields[0].type,
                                  "fieldName",
                                  e.target.value
                                );
                              }}
                            />
                            <button
                              className="delete_btn"
                              onClick={() => {
                                setActiveQuestion(i.question);
                                setFieldModal(true)
                              }}
                            >
                              <img src="assets/img/delete.png" />
                            </button>
                          </InputStyledComp>
                        ) : i.fields[0].type == "date" ? (
                          <InputStyledComp key={ix}>
                            <div className="field_name sr_number">
                              <span>
                                {extractNumber(i.question)}{" "}
                                <FontAwesomeIcon icon={faArrowRight} />
                              </span>
                              <input
                                type="text"
                                placeholder={"Display Name"}
                                value={i.fields[0].displayName}
                                onChange={(e) => {
                                  handleEditFormValue(
                                    i.question,
                                    i.fields[0].type,
                                    "displayName",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                            <input
                              type="text"
                              value={i.fields[0].description}
                              className="discription"
                              placeholder="Discription (optional)"
                              onChange={(e) => {
                                handleEditFormValue(
                                  i.question,
                                  i.fields[0].type,
                                  "description",
                                  e.target.value
                                );
                              }}
                            />
                            {/* <input type="date" name="datePick" className='input_box' placeholder='check' value={i.fields[0].fieldName} onChange={(e) => { handleEditFormValue(i.question, i.fields[0].type, 'fieldName', e.target.value) }} /> */}
                            <input
                              type="text"
                              className="input_box"
                              placeholder={i.fields[0].placeholder}
                            // placeholder="Enter the field name"
                            // value={i.fields[0].fieldName}
                            // onChange={(e) => {
                            //   handleEditFormValue(
                            //     i.question,
                            //     i.fields[0].type,
                            //     "fieldName",
                            //     e.target.value
                            //   );
                            // }}
                            />
                            <button
                              className="delete_btn"
                              onClick={() => {
                                setActiveQuestion(i.question);
                                setFieldModal(true)
                              }}
                            >
                              <img src="assets/img/delete.png" />
                            </button>
                          </InputStyledComp>
                        ) : i.fields[0].type == "address" ? (
                          <InputStyledComp key={ix}>
                            <div className="field_name sr_number">
                              <span>
                                {extractNumber(i.question)}{" "}
                                <FontAwesomeIcon icon={faArrowRight} />
                              </span>
                              <input
                                type="text"
                                placeholder={"Display Name"}
                                value={i.fields[0].displayName}
                                onChange={(e) => {
                                  handleEditFormValue(
                                    i.question,
                                    i.fields[0].type,
                                    "displayName",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                            <input
                              type="text"
                              value={i.fields[0].description}
                              className="discription"
                              placeholder="Discription (optional)"
                              onChange={(e) => {
                                handleEditFormValue(
                                  i.question,
                                  i.fields[0].type,
                                  "description",
                                  e.target.value
                                );
                              }}
                            />
                            <textarea
                              rows={4} // Specify the number of visible rows
                              cols={50} // Specify the number of visible columns
                              placeholder="Enter your multiline text here..."
                              value={i.fields[0].fieldName}
                              onChange={(e) => {
                                handleEditFormValue(
                                  i.question,
                                  i.fields[0].type,
                                  "fieldName",
                                  e.target.value
                                );
                              }}
                            />
                            <button
                              className="delete_btn"
                              onClick={() => {
                                setActiveQuestion(i.question);
                                setFieldModal(true)
                              }}
                            >
                              <img src="assets/img/delete.png" />
                            </button>
                          </InputStyledComp>
                        ) : i.fields[0].type == "end_screen" ? (
                          <InputStyledComp key={ix}>
                            <input
                              type="text"
                              className="input_box"
                              placeholder={i.fields[0].placeholder}
                            // placeholder={"End screen"}
                            // value={i.fields[0].description}
                            // onChange={(e) => {
                            //   handleEditFormValue(
                            //     i.question,
                            //     i.fields[0].type,
                            //     "description",
                            //     e.target.value
                            //   );
                            // }}
                            />
                            <button
                              className="delete_btn"
                              onClick={() => {
                                setActiveQuestion(i.question);
                                setFieldModal(true)
                              }}
                            >
                              <img src="assets/img/delete.png" />
                            </button>
                          </InputStyledComp>
                        ) : i.fields[0].type == "multiple_options" ? (
                          <MultipleOption key={ix}>
                            <div className="field_name sr_number">
                              <span>
                                {extractNumber(i.question)}{" "}
                                <FontAwesomeIcon icon={faArrowRight} />
                              </span>
                              <input
                                type="text"
                                placeholder={"Display Name"}
                                value={i.fields[0].displayName}
                                onChange={(e) => {
                                  handleEditFormValue(
                                    i.question,
                                    i.fields[0].type,
                                    "displayName",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                            <input
                              type="text"
                              value={i.fields[0].description}
                              className="discription"
                              placeholder="Discription (optional)"
                              onChange={(e) => {
                                handleEditFormValue(
                                  i.question,
                                  i.fields[0].type,
                                  "description",
                                  e.target.value
                                );
                              }}
                            />
                            <div className="mt-3 multi_option_btn">
                              {i.fields[0].options.map(
                                (j: any, jx: number) => {
                                  return (
                                    <div className="option_box_field" key={jx}>
                                      <span>{jx + 1}</span>
                                      <input
                                        type="text"
                                        placeholder="Enter Option"

                                        value={j.value.replace(/_/g, " ")}
                                        // className="input_box"
                                        onChange={(e) => {
                                          updateOptions(
                                            i.question,
                                            jx,
                                            e.currentTarget.value,
                                            "value"
                                          );
                                        }}
                                      />
                                      <button className="delete_option" onClick={() => { deleteOptions(i.question, jx) }}><img src="assets/img/delete.png" /></button>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                            <button
                              className="option_btn"
                              onClick={() => {
                                addOptions(i.question);
                              }}
                            >
                              Add Option
                            </button>
                            <button
                              className="delete_btn"
                              onClick={() => {
                                setActiveQuestion(i.question);
                                setFieldModal(true)
                              }}
                            >
                              <img src="assets/img/delete.png" />
                            </button>
                          </MultipleOption>
                        ) : i.fields[0].type == "payment" ? (
                          <PaymentFiels key={ix}>
                            <div className="field_name sr_number">
                              <span>
                                {extractNumber(i.question)}{" "}
                                <FontAwesomeIcon icon={faArrowRight} />
                              </span>
                              <input
                                type="text"
                                value={i.fields[0].displayName}
                                placeholder={"Display Name"}
                                onChange={(e) => {
                                  handleEditFormValue(
                                    i.question,
                                    i.fields[0].type,
                                    "displayName",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                            <input
                              type="text"
                              value={i.fields[0].description}
                              className="discription"
                              placeholder="Discription (optional)"
                              onChange={(e) => {
                                handleEditFormValue(
                                  i.question,
                                  i.fields[0].type,
                                  "description",
                                  e.target.value
                                );
                              }}
                            />
                            <input
                              type="number"
                              className="input_box"
                              placeholder={i.fields[0].placeholder}
                              value={i.fields[0].amount}
                              onChange={(e) => {
                                handleEditFormValue(
                                  i.question,
                                  i.fields[0].type,
                                  "amount",
                                  e.target.value
                                );
                              }}
                            />
                            <button
                              className="delete_btn"
                              onClick={() => {
                                setActiveQuestion(i.question);
                                setFieldModal(true)
                              }}
                            >
                              <img src="assets/img/delete.png" />
                            </button>
                          </PaymentFiels>
                        ) : (
                          <div className="content_box">
                            <h2>Please create a question to continue</h2>
                          </div>
                        );
                      })
                  }
                  {userType == "Create" && newFormData.form.length == 0 ? (
                    <>
                      <h2 className="m-auto">
                        Please create a question to continue{" "}
                        <h6
                          className="text-dark mb-3"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setQuestionBox(true);
                          }}
                        >
                          {" "}
                          Add Questions{" "}
                        </h6>
                      </h2>
                    </>
                  ) : userType == "Update" &&
                    updateFormData.form.length == 0 ? (
                    <>
                      <h2 className="m-auto">
                        Please create a question to continue{" "}
                        <h6
                          className="text-dark mb-3"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setQuestionBox(true);
                          }}
                        >
                          {" "}
                          Add Questions{" "}
                        </h6>
                      </h2>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
              <Col md={5} sm={12}>
                {/* end_screen */}
                <div className="content_box">
                  {updateFormData.form?.filter((item: any) => { return item.question === activeField })[0]?.fields[0].type != 'end_screen' || newFormData.form?.filter((item: any) => { return item.question === activeField })[0]?.fields[0].type != 'end_screen' ? 
                   <>
                   {newFormData.form.length > 0 || updateFormData.form.length > 0 == true ? <h4>Settings</h4> : ''}

                   {userType == "Create"
                     ? newFormData.form
                       ?.filter((item: any) => {
                         return item.question === activeField;
                       })
                       .map((i: any, ix: number) => {
                         return (
                           <div className="d-flex justify-content-between mt-4" key={ix}>
                             <h6>Required</h6>
                             <span onClick={() => { }}>
                               <Toggle
                                 size="sm"
                                 checked={i.fields[0].required}
                                 onChange={(e: boolean) => {
                                   handleEditFormValue(
                                     i.question,
                                     i.fields[0].type,
                                     "required",
                                     !i.fields[0].required
                                   );
                                 }}
                               />
                             </span>
                           </div>
                         );
                       })
                     : updateFormData.form
                       ?.filter((item: any) => {
                         return item.question === activeField;
                       })
                       .map((i: any, ix: number) => {
                         return (
                           <div className="d-flex justify-content-between mt-4" key={ix}>
                             <p>Required</p>
                             <span onClick={() => { }}>
                               <Toggle
                                 size="sm"
                                 checked={i.fields[0].required}
                                 onChange={(e: boolean) => {
                                   handleEditFormValue(
                                     i.question,
                                     i.fields[0].type,
                                     "required",
                                     !i.fields[0].required
                                   );
                                 }}
                               />
                             </span>
                           </div>
                         );
                       })}

                   {/* Multiple selection  Multiple selection */}
                   {userType == "Create"
                     ? newFormData.form
                       ?.filter((item: any) => {
                         return item.question === activeField && item.fields[0].type == "multiple_options";
                       })
                       .map((i: any, ix: number) => {
                         return (
                           <div className="d-flex justify-content-between mt-4" key={ix}>
                             <h6>Multiple selection</h6>
                             <span onClick={() => { }}>
                               <Toggle
                                 size="sm"
                                 checked={i.fields[0].multipleSelection}
                                 onChange={(e: boolean) => {
                                   handleEditFormValue(
                                     i.question,
                                     i.fields[0].type,
                                     "multipleSelection",
                                     !i.fields[0].multipleSelection
                                   );
                                 }}
                               />
                             </span>
                           </div>
                         );
                       })
                     : updateFormData.form
                       ?.filter((item: any) => {
                         return item.question === activeField && item.fields[0].type == "multiple_options";
                       })
                       .map((i: any, ix: number) => {
                         return (
                           <div className="d-flex justify-content-between mt-4" key={ix}>
                             <p>Multiple selection</p>
                             <span onClick={() => { }}>
                               <Toggle
                                 size="sm"
                                 checked={i.fields[0].multipleSelection}
                                 onChange={(e: boolean) => {
                                   handleEditFormValue(
                                     i.question,
                                     i.fields[0].type,
                                     "multipleSelection",
                                     !i.fields[0].multipleSelection
                                   );
                                 }}
                               />
                             </span>
                           </div>
                         );
                       })}


                   {userType == "Create"
                     ? newFormData.form
                       ?.filter((item: any) => {
                         return item.question === activeField;
                       })
                       .map((i: any, ix: number) => {
                         if (i.fields[0].type != "file_upload") {
                           return (
                             <div className="condition_box" key={ix}>
                               <h6>Conditions</h6>
                               <button onClick={() => addCondition(i)}>
                                 + Add condition
                               </button>
                               {i.conditionRule && i.conditionRule.map((item: any, index: number) => {
                                 if (i.fields[0].type == 'date') {
                                   return (
                                     <div className="condition-container">
                                       <div className="condition_title_bar"> <h6>Condition {index + 1}</h6> <button onClick={() => { handleDeleteCondition(i.question, index) }}> <img className="delete-btn" src="assets/img/delete.png" /></button></div>
                                       <p className="conditions">If Response  <select name="select_condition" value={item.if} onChange={(e) => { updateCondition(i, 'if', e.target.value, index) }}>
                                         <option value="is">Is</option>
                                         <option value="is_not">Is Not</option>
                                       </select> Within last <input type='number' placeholder="Enter Days" value={item.value} onChange={(e) => { updateCondition(i, 'value', e.target.value, index) }} /> Then Go to <select name="select_condition" value={item.then_go_to} onChange={(e) => { updateCondition(i, 'then_go_to', e.target.value, index) }}>
                                           {newFormData.form.map((j: any, jx: number) => {
                                             return (
                                               <option value={j.question.replace("step", "question")} key={jx}>Question {j.question.split('_')[1]}</option>
                                             )
                                           })}
                                         </select> </p>
                                     </div>
                                   )
                                 } else if (i.fields[0].type == 'multiple_options') {
                                   return (
                                     <div className="condition-container">
                                       <div className="condition_title_bar"> <h6>Condition {index + 1}</h6> <button onClick={() => { handleDeleteCondition(i.question, index) }}> <img className="delete-btn" src="assets/img/delete.png" /></button></div>
                                       <p className="conditions">If Response is <select name="select_condition" value={item.value}
                                         onChange={(e) => { updateCondition(i, "value", e.target.value, index) }}>

                                         {i.fields[0].options.map(
                                           (k: any, kx: number) => {
                                             return (
                                               <option value={k.value}>
                                                 {k.value}
                                               </option>
                                             );
                                           }
                                         )}
                                       </select>
                                         {/* <input type='text' value={item.value} onChange={(e)=>{updateCondition(i,'value',e.target.value,index)}}/> */}
                                         Then Go to <select name="select_condition" value={item.then_go_to} onChange={(e) => { updateCondition(i, 'then_go_to', e.target.value, index) }}>
                                           {newFormData.form.map((j: any) => {
                                             return (
                                               <option value={j.question.replace("step", "question")}>Question {j.question.split('_')[1]}</option>
                                             )
                                           })}
                                         </select> </p>
                                     </div>
                                   )
                                 } else {
                                   return (
                                     <div className="condition-container">
                                       <div className="condition_title_bar"> <h6>Condition {index + 1}</h6> <button onClick={() => { handleDeleteCondition(i.question, index) }}> <img className="delete-btn" src="assets/img/delete.png" /></button></div>
                                       <p className="conditions">If Response  <select name="select_condition" value={item.if} onChange={(e) => { updateCondition(i, 'if', e.target.value, index) }}>
                                         <option value="is">Contain</option>
                                         <option value="is_not">Not Contain</option>
                                       </select> <input type='text' value={item.value} onChange={(e) => { updateCondition(i, 'value', e.target.value, index) }} /> Then Go to <select name="select_condition" value={item.then_go_to} onChange={(e) => { updateCondition(i, 'then_go_to', e.target.value, index) }}>
                                           {newFormData.form.map((j: any) => {
                                             return (
                                               <option value={j.question.replace("step", "question")}>Question {j.question.split('_')[1]}</option>
                                             )
                                           })}
                                         </select> </p>
                                     </div>
                                   )
                                 }
                               }
                               )}
                             </div>
                           )
                         }
                       })
                     : updateFormData.form
                       ?.filter((item: any) => {
                         return item.question === activeField;
                       })
                       .map((i: any, ix: number) => {
                         if (i.fields[0].type != "file_upload") {
                           return (
                             <div className="condition_box" key={ix}>
                               <h6>Conditions</h6>
                               <button onClick={() => addCondition(i)}>
                                 + Add condition
                               </button>
                               {i.conditionRule && i.conditionRule.map((item: any, index: number) => {
                                 if (i.fields[0].type == 'date') {
                                   return (
                                     <div className="condition-container">
                                       <div className="condition_title_bar"> <h6>Condition {index + 1}</h6> <button onClick={() => { handleDeleteCondition(i.question, index) }}><img className="delete-btn" src="assets/img/delete.png" /></button></div>
                                       <p className="conditions">If Response  <select name="select_condition" value={item.if} onChange={(e) => { updateCondition(i, 'if', e.target.value, index) }}>
                                         <option value="is">Is</option>
                                         <option value="is_not">Is Not</option>
                                       </select> Within last <input type='number' placeholder="Enter Days" value={item.value} onChange={(e) => { updateCondition(i, 'value', e.target.value, index) }} /> Then Go to <select name="select_condition" value={item.then_go_to} onChange={(e) => { updateCondition(i, 'then_go_to', e.target.value, index) }}>
                                           {updateFormData.form.map((j: any) => {
                                             return (
                                               <option value={j.question.replace("step", "question")}>Question {j.question.split('_')[1]}</option>
                                             )
                                           })}
                                         </select> </p>
                                     </div>
                                   )
                                 } else if (i.fields[0].type == 'multiple_options') {
                                   return (
                                     <div className="condition-container">
                                       <div className="condition_title_bar"> <h6>Condition {index + 1}</h6> <button onClick={() => { handleDeleteCondition(i.question, index) }}> <img className="delete-btn" src="assets/img/delete.png" /></button></div>
                                       <p className="conditions">If Response is <select name="select_condition" value={item.value}
                                         onChange={(e) => { updateCondition(i, "value", e.target.value, index) }}>
                                         {/* <option value="none" selected disabled hidden>Select an Option</option>  */}
                                         {i.fields[0].options.map(
                                           (k: any, kx: number) => {
                                             return (
                                               <option value={k.value}>
                                                 {k.displayName}
                                               </option>
                                             );
                                           }
                                         )}
                                       </select>
                                         {/* <input type='text' value={item.value} onChange={(e)=>{updateCondition(i,'value',e.target.value,index)}}/> */}
                                         Then Go to <select name="select_condition" value={item.then_go_to} onChange={(e) => { updateCondition(i, 'then_go_to', e.target.value, index) }}>
                                           {updateFormData.form.map((j: any) => {
                                             return (
                                               <option value={j.question.replace("step", "question")}>Question {j.question.split('_')[1]}</option>
                                             )
                                           })}
                                         </select> </p>
                                     </div>
                                   )
                                 } else {
                                   return (
                                     <div className="condition-container">
                                       <div className="condition_title_bar"> <h6>Condition {index + 1}</h6> <button onClick={() => { handleDeleteCondition(i.question, index) }}> <img className="delete-btn" src="assets/img/delete.png" /></button></div>
                                       <p className="conditions">If Response  <select name="select_condition" value={item.if} onChange={(e) => { updateCondition(i, 'if', e.target.value, index) }}>
                                         <option value="is">Contain</option>
                                         <option value="is_not">Not Contain</option>
                                       </select> <input type='text' value={item.value} onChange={(e) => { updateCondition(i, 'value', e.target.value, index) }} /> Then Go to <select name="select_condition" value={item.then_go_to} onChange={(e) => { updateCondition(i, 'then_go_to', e.target.value, index) }}>
                                           {updateFormData.form.map((j: any) => {
                                             return (
                                               <option value={j.question.replace("step", "question")}>Question {j.question.split('_')[1]}</option>
                                             )
                                           })}
                                         </select> </p>
                                     </div>
                                   )
                                 }
                               }
                               )}
                             </div>
                           );
                         } else {
                         }
                       })}
                 </>
                   :
                   ''
                  }

                </div>
              </Col>
            </Row>
            <button
              className="btn fw-bold float-right auto_btn mt-3"
              style={{ float: 'right' }}
              onClick={() => {
                handleSubmitForm();
              }}
            >
              Submit
            </button>
          </div>
        </Spin>
      </DialogBox>
      <DialogBox
        boxSize="lg"
        openDialog={questionBox}
        onClickCloseDialog={(e) => setQuestionBox(e)}
      >
        <label htmlFor="lead-date" className="form-label col-md-6">
          <b>Select question type</b>
        </label>
        {/* <div className="d-flex flex-wrap"> */}
        <div className="question_box">
          <button
            className="action_button col-2"
            onClick={() => {
              handleAddQuestion("name");
            }}
          >
            <img src="assets/img/sortText.png" /> Name
          </button>
          <button
            className="action_button col-2"
            onClick={() => {
              handleAddQuestion("short_text");
            }}
          >
            <img src="assets/img/sortText.png" /> Short text
          </button>

          <button
            className="action_button col-2"
            onClick={() => {
              handleAddQuestion("email");
            }}
          >
            <img src="assets/img/darkMail.png" /> E-mail
          </button>
          <button
            className="action_button col-2"
            onClick={() => {
              handleAddQuestion("phone");
            }}
          >
            <img src="assets/img/phone.png" /> Phone number
          </button>
          <button
            className="action_button col-2"
            onClick={() => {
              handleAddQuestion("file_upload");
            }}
          >
            <img src="assets/img/file.png" /> File upload
          </button>
          <button
            className="action_button col-2"
            onClick={() => {
              handleAddQuestion("long_text");
            }}
          >
            <img src="assets/img/text.png" /> Long Text
          </button>
          <button
            className="action_button col-2"
            onClick={() => {
              handleAddQuestion("date");
            }}
          >
            <img src="assets/img/date.png" /> Date
          </button>
          <button
            className="action_button col-2"
            onClick={() => {
              handleAddQuestion("number");
            }}
          >
            <img src="assets/img/number.png" /> Number
          </button>

          <button
            className="action_button col-2"
            onClick={() => {
              handleAddQuestion("end_screen");
            }}
          >
            <img src="assets/img/endScreen.png" /> End screen
          </button>
          <button
            className="action_button col-2"
            onClick={() => {
              handleAddQuestion("payment");
            }}
          >
            <img src="assets/img/dollar.png" /> Payment
          </button>
          <button
            className="action_button col-2"
            onClick={() => {
              handleAddQuestion("address");
            }}
          >
            <img src="assets/img/location.png" />Address
          </button>
          <button
            className="action_button col-2"
            onClick={() => {
              handleAddQuestion("multiple_options");
            }}
          >
            <img src="assets/img/check.png" /> Multiple options
          </button>
        </div>
      </DialogBox>

      <DynamicConfirmationModal
        onConfirmation={() => deleteField()}
        showModal={fieldModal}
        toggleModal={() => { setFieldModal(false) }}
        title="Are you sure to want to Delete this Field"
      />

      <DynamicConfirmationModal
        onConfirmation={() => { handleCloseForm(); setFormOpen(false); setFormCloseModel(false); }}
        showModal={formCloseModel}
        toggleModal={() => { setFormCloseModel(false) }}
        title="Are you sure to want to Close this Form"
      />

      <DynamicConfirmationModal
        onConfirmation={() => { handleCloseForm(); setFullScreenForm(false); setStepFormModel(false); }}
        showModal={stepFormModel}
        toggleModal={() => { setStepFormModel(false) }}
        title="Are you sure to want to Close this Form"
      />

      <Toaster position="top-center" reverseOrder={false} />
    </Root>
  );
};

export default UserList;

const Root = styled.div`



@media (max-width: 500px){
  
}
th ,td{
  text-transform: capitalize;
}
  @media screen and (max-width: 600px) {
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    display: block;
    border:none;
    border-radius:0px;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
    border-radius:0px;
  }
  
  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}

`