import React, { useCallback } from 'react';
import { Button } from "antd";
import { FaRegFile } from "react-icons/fa";
import { isImage, isVideo } from "../../../../utils/helpers";
import { WhatsAppPreviewProps } from './types';
import styled from 'styled-components';

const WhatsAppPreview: React.FC<WhatsAppPreviewProps> = ({
  bodyValue,
  values,
  file
}) => {
  const renderVideo = useCallback(() => {
    if (!file) return null;
    
    return (
      <video
        src={URL.createObjectURL(file)}
        style={{ maxWidth: "100%", height: 200, width: 200 }}
        controls
      />
    );
  }, [file]);

  return (
    <div className="whatsappScreen">
      <div className="whatsHead d-flex align-items-center">
        <svg
          viewBox="0 0 24 24"
          focusable="false"
          className="back-icon"
        >
          <path
            fill="currentColor"
            d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"
          />
        </svg>
        <div className="d-flex flex-fill align-items-center">
          <img
            src="./assets/img/whatsapp.svg"
            className="whatsapp-logo"
            alt="WhatsApp"
          />
          <span className="text-white ms-2">Whatsapp</span>
          <img
            src="./assets/img/verified.svg"
            className="verified ms-2"
            alt="Verified"
          />
        </div>
        <svg
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          focusable="false"
          className="dot-icon text-white"
          height="14"
          width="14"
        >
          <circle cx="12" cy="12" r="1"></circle>
          <circle cx="12" cy="5" r="1"></circle>
          <circle cx="12" cy="19" r="1"></circle>
        </svg>
      </div>

      <div className="whatsBody" style={{
  maxHeight: "480px", // Increased from 400px for more space
  overflowY: "auto",  // Changed from "hidden" to "auto"
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  padding: "16px"
}}>
      {(bodyValue?.length || values?.body) && (
          <div className="whatsBody_message" style={{ maxWidth: 350, overflowWrap: "break-word" }}>
            {file && (
              isImage(values.header_value) ? (
                <Preview>
                  <img
                    src={URL.createObjectURL(file)}
                    height={100}
                    width={150}
                    style={{ borderRadius: 5 }}
                    alt="Preview"
                  />
                </Preview>
              ) : isVideo(values.header_value) ? (
                <Preview>{renderVideo()}</Preview>
              ) : (
                <PdfView>
                  <FaRegFile size={20} color="#000" />
                  <span>{file?.name}</span>
                </PdfView>
              )
            )}
           <div className="fm-gilroy" style={{ whiteSpace: "pre-wrap" }}>
  {bodyValue || values?.body}
</div>
            {values.footer && (
              <div style={{ fontWeight: "bold", marginTop: "0.4rem" }}>
                {values.footer}
              </div>
            )}
            {values.haveButtons === "yes" && (
              <div style={{
                marginTop: "0.8rem",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}>
                {values.buttons?.map((button: { text: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }, i: React.Key | null | undefined) => (
                  <Button key={i} className="btn w-100">
                    {button.text}
                  </Button>
                ))}
              </div>
            )}
          </div>
        )}
      </div>

      <div className="whatsFooter">
        <div className="d-flex">
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 496 512"
            focusable="false"
            className="smiley-icon"
            height="1em"
            width="1em"
          >
            <path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm80 152c17.7 0 32 14.3 32 32s-14.3 32-32 32-32-14.3-32-32 14.3-32 32-32zm-160 0c17.7 0 32 14.3 32 32s-14.3 32-32 32-32-14.3-32-32 14.3-32 32-32zm88 272h-16c-73.4 0-134-55-142.9-126-1.2-9.5 6.3-18 15.9-18h270c9.6 0 17.1 8.4 15.9 18-8.9 71-69.5 126-142.9 126z" />
          </svg>
          <input type="text" placeholder="Message" />
          <svg
            className="file-icon"
            height="1em"
            width="1em"
            viewBox="0 0 16 16"
          >
            <path d="M10.404 5.11l-1.015-1.014-5.075 5.074c-0.841 0.841-0.841 2.204 0 3.044s2.204 0.841 3.045 0l6.090-6.089c1.402-1.401 1.402-3.673 0-5.074s-3.674-1.402-5.075 0l-6.394 6.393c-0.005 0.005-0.010 0.009-0.014 0.013-1.955 1.955-1.955 5.123 0 7.077s5.123 1.954 7.078 0c0.004-0.004 0.008-0.009 0.013-0.014l0.001 0.001 4.365-4.364-1.015-1.014-4.365 4.363c-0.005 0.004-0.009 0.009-0.013 0.013-1.392 1.392-3.656 1.392-5.048 0s-1.392-3.655 0-5.047c0.005-0.005 0.009-0.009 0.014-0.013l-0.001-0.001 6.395-6.393c0.839-0.84 2.205-0.84 3.045 0s0.839 2.205 0 3.044l-6.090 6.089c-0.28 0.28-0.735 0.28-1.015 0s-0.28-0.735 0-1.014l5.075-5.075z" />
          </svg>
          <svg
            className="camera-icon"
            height="1em"
            width="1em"
            viewBox="0 0 1024 1024"
          >
            <path d="M864 260H728l-32.4-90.8a32.07 32.07 0 0 0-30.2-21.2H358.6c-13.5 0-25.6 8.5-30.1 21.2L296 260H160c-44.2 0-80 35.8-80 80v456c0 44.2 35.8 80 80 80h704c44.2 0 80-35.8 80-80V340c0-44.2-35.8-80-80-80zM512 716c-88.4 0-160-71.6-160-160s71.6-160 160-160 160 71.6 160 160-71.6 160-160 160zm-96-160a96 96 0 1 0 192 0 96 96 0 1 0-192 0z" />
          </svg>
        </div>
        <img src="./assets/img/mic.png" className="ms-2" alt="Voice message" />
      </div>
    </div>
  );
};

const Preview = styled.div`
  display: flex;
  justify-content: center;
`;

const PdfView = styled.div`
  width: 180px;
  height: 40px;
  display: flex;
  align-items: center;
  background-color: #fff;
  margin-bottom: 5px;
  border-radius: 5px;
  padding: 0px 5px;
  overflow: hidden;

  span {
    color: #000;
    font-size: 12px;
    margin-left: 5px;
    font-family: "Gilroy-SemiBold";
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    max-width: 85%;
  }
`;

export default WhatsAppPreview;