import React, {
  useState,
  ChangeEvent,
  useMemo,
  useRef,
  useEffect,
  useCallback,
} from "react";
import Header from "../../../components/header";
import Inputs from "../../../components/inputs";
import "./createTemplate.css";
import { Formik, ErrorMessage, FormikProps } from "formik";
import { Button, Spin } from "antd";
import { styled } from "styled-components";
import { createTemplate, updateTemplate } from "../../../services/templates";
import { toast } from "react-hot-toast";
import { upperCase } from "lodash";
import MyToolTip from "../../../components/MyToolTip";
import DrawerExistModal from "../../../components/drawerExistModal";
import { languageObj } from "../../../components/common";
import { useSelector } from "react-redux";
import { uploadMedia } from "../../../services/whatsapp";
import BodySection from "./bodySection";
import ButtonSection from "./buttonSection/buttonSection";
import HeaderSection from "./headerSection/headerSection";
import WhatsAppPreview from './WhatsAppPreview';



const isValidUrl = (url: string): boolean => {
  const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/;
  return urlPattern.test(url);
};

interface ExpandedSections {
  [key: string]: boolean;
}
interface TeamMember {
  value: string;
  label: string;
  type: string;
}
interface Props {
  toggleCreate: (value: string) => void;
  editTemplateData: any;
}

let typeValues = {
  name: "Vivek rana",
  phone: "+91 7015823248",
  email: "vivek@3sigmacrm.com",
  notes: "this is a sample note",
  text: "dummy text",
  number: "01274894949",
  amount: "848848",
  address: "212,central dekhi second flow, 202,122003 Gurgaon haryana",
  option: "option 1",
  selection: "option 1, option 2",
  date: "24 August 2023 5,30 pm",
  time: "16:32",
};

const defaultButtons = [
  {
    type: "",
    text: "",
    country: "",
    phone_number: "",
  },
  {
    type: "",
    text: "",
    url: "",
  },
];

const CreateTemplate: React.FC<Props> = ({
  toggleCreate,
  editTemplateData,
}) => {
  const formikRef = useRef<FormikProps<any>>(null);
  
  useEffect(() => {
    console.log("Initial template data:", editTemplateData?.name);
  }, [editTemplateData]);

  const [disabled, setDisabled] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [bodyValue, setBodyValue] = useState<string>(
    editTemplateData?.components
      ?.find((item: any) => item.type === "BODY")
      ?.text?.replace(/\{\{(\d+)\}\}/g, (_: any, i: number) => {
        return editTemplateData?.mapper?.[i + 1] ?? ""
      }) ?? ""
  );
  useEffect(() => {
    if (editTemplateData?.components) {
      const headerDoc = editTemplateData.components.find(
        (        c: { type: string; format: string; }) => c.type === "HEADER" && c.format === "DOCUMENT"
      );
      if (headerDoc?.example?.header_handle?.[0]) {
        // Instead of using the full URL, let's use a cleaner name
        setFileName("business_introduction.pdf"); // Or whatever default name is appropriate
      }
    }
  }, [editTemplateData]);
  const [fileName, setFileName] = useState<string>("");
  const [showExitModal, setShowExitModal] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [confirmShowExitModal, setConfirmShowExitModal] =
    useState<boolean>(false);
  // const [assignLeadDrawer, setAssignLeadDrawer] = useState(false);
  const [formValue, setFormValue] = useState({});
  const [expandedSections, setExpandedSections] = useState<ExpandedSections>({
    section1: true,
    section2: true,
    section3: true,
  });

  const [file, setFile] = useState<any>(null);
  const [isError, setIsError] = useState<boolean>(false);

  const StateData = useSelector((state: any) => {
    return state?.rootReducers;
  });
  const handleSetFieldValue = (field: string, value: any) => {
    if (formikRef.current) {
      formikRef.current.setFieldValue(field, value);
    }
  };
  
  const teamMembers: TeamMember[] =
    StateData?.userData?.userDetails?.userPreference?.customForm?.map(
      (item: any) => {
        return {
          value: item?.value,
          label: item?.name,
          type: item?.type,
        };
      }
    ) || [];

  useEffect(() => {
    if (formikRef?.current) {
      setIsError(!!Object.keys(formikRef.current?.errors)?.length || false);
    }
  }, [formikRef?.current]);

  useEffect(() => {
    if (formikRef?.current?.values?.header_value && !file) {
      formikRef?.current?.setFieldValue("header_value", "");
      setFileName("");
    }
  }, [file]);

  const toggleSection = (sectionId: string) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [sectionId]: !prevState[sectionId],
    }));
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file: any = event.target.files?.[0];
    setFileName(file ? file.name : "");
    setFile(file);
  };

  const toggleEditModal = () => {
    setShowExitModal(!showExitModal);
  };
  const toggleConfirmEditModal = () => {
    setConfirmShowExitModal(!confirmShowExitModal);
  };

  const handleDrawerClick = () => {
    setShowExitModal(!showExitModal);
    toggleCreate("");
  };

  const handleConfirmDrawerClick = async () => {
    setConfirmShowExitModal(!confirmShowExitModal);
    setIsSubmitting(true);

    try {
      const userIntigrationsList = JSON.parse(
        localStorage.getItem("userData") || ""
      ).userIntegrations;

      const findData = userIntigrationsList.find(
        (item: any) =>
          item?.integration?._id === "646b9286ae8ef73b33caec2e" &&
          item?.isActive
      );

      handler(formValue)
        .then(() => {
          toggleCreate("success");
          toast.success(
            `Template ${editTemplateData ? "update" : "created"} successfully.`
          );
          setIsSubmitting(false);
        })
        .catch((error) => {
          console.log("error>>", error);
          setIsSubmitting(false);
          toast.error(
            error?.response?.data?.message ??
              `Something went wrong while ${
                editTemplateData ? "updating" : "creating"
              } template`
          );
        });
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  const handler = async (values: any): Promise<any> => {
    console.log("Form values at submission:", {
      rawName: values.name,
      valueType: typeof values.name
    });
    
    const components = [];
    let mapper: { [key: string]: string } = {};

    // Handle HEADER component
    if (values.header_value) {
      if (file) {
        const formData = new FormData();

        formData.append("files", file);
        formData.append("isTemplateFile", "true");

        const response = await uploadMedia(formData);

        if (response?.data) {
          components.push({
            type: "HEADER",
            format: upperCase(values.header),
            example: {
              header_handle: [response?.data?.data[0]?.uploadedId],
            },
          });
        }
      } else {
        components.push({
          type: "HEADER",
          format: upperCase(values.header),
          text: values.header_value,
        });
      }
    }

    // Handle BODY component
    if (values.body) {
      const bodyTypes =
        values.body
          .match(/{{(.*?)}}/g)
          ?.map((val: any) => val.replace(/{{|}}/g, "")) || [];
      let body_text: string[][] = [];

      if (bodyTypes.length > 0) {
        body_text = bodyTypes.map((type: string) => {
          const teamMember = teamMembers.find(
            (member: TeamMember) => member.value === type
          );
          return [
            teamMember
              ? typeValues[teamMember.type as keyof typeof typeValues]
              : type,
          ];
        });
      } else {
        // If no variables, use the entire body as a single item in a nested array
        body_text = [[values.body]];
      }

      if (values?.body?.match(/{{(.*?)}}/g)?.length) {
        let count = 1;
        components.push({
          type: "BODY",
          text: values.body?.replace(/\{\{.*?\}\}/g, () => `{{${count++}}}`),
          example: {
            body_text: [body_text?.flat()],
          },
        });

        // Creating mapper object
        values?.body?.match(/{{(.*?)}}/g)?.forEach((val: any, i: number) => {
          mapper[`${i + 1}`] = val;
        });
      } else {
        components.push({
          type: "BODY",
          text: values.body,
        });
      }
    }

    // Handle BUTTONS component
    if (values.haveButtons === "yes" && values.buttons) {
      components.push({
        type: "BUTTONS",
        buttons: values.buttons.map((button: any) => ({
          type: button.type,
          text: button.text,
          ...(button.type === "PHONE_NUMBER"
            ? { phone_number: button.phone_number }
            : {}),
          ...(button.type === "URL" ? { url: button.url } : {}),
        })),
      });
    }

    // Handle FOOTER component
    if (values.footer) {
      components.push({ type: "FOOTER", text: values.footer });
    }

    const data = {
      name: values.name.toLowerCase(),
      language: values.language,
      category: values.category,
      allowCategoryChange: values.allowCategoryChange === "yes",
      components,
      mapper,
    };

    console.log("API payload data:", {
      payloadName: data.name,
      valueType: typeof data.name
    });

    if (editTemplateData) {
      return await updateTemplate(editTemplateData._id, data);
    }

    return await createTemplate(data);
  };

  const initialValues = useMemo(() => {
    if (editTemplateData) {
      const header = editTemplateData?.components.find(
        (c: any) => c.type === "HEADER"
      );
      const body = editTemplateData?.components.find(
        (c: any) => c.type === "BODY"
      );
      const footer = editTemplateData?.components.find(
        (c: any) => c.type === "FOOTER"
      );
      const buttons = editTemplateData?.components.find(
        (c: any) => c.type === "BUTTONS"
      );
      return {
        name: editTemplateData.name,
        category: editTemplateData.category,
        language: editTemplateData.language,
        allowCategoryChange: editTemplateData.allowCategoryChange
          ? "yes"
          : "no",
        header: header ? header.format.toLowerCase() : "none",
        header_value: header?.text || "",
        haveButtons: buttons ? "yes" : "no",
        footer: footer?.text || "",
        body: body?.text || "",
        buttons: buttons?.buttons || defaultButtons,
      };
    }
    return {
      name: "",
      category: "",
      language: "en",
      allowCategoryChange: "yes",
      header: "none",
      haveButtons: "no",
      footer: "",
      body: "",
      header_value: "",
      buttons: defaultButtons,
    };
  }, [editTemplateData]);

// Add after initialValues useMemo
useEffect(() => {
  console.log("Initial template name value:", initialValues.name);
}, [initialValues]);

  const isURL = (str: string) => {
    const urlRegex =
      /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
    return urlRegex.test(str);
  };

  

  return (
    <Spin size="large" tip="Loading..." spinning={isSubmitting}>
      <div id="main" className="main">
        <Header />
        <Formik
  enableReinitialize
  innerRef={formikRef}
  initialValues={initialValues}
  validateOnChange={isError}
  validate={(values) => {
    console.log("Validation running with values:", {
      templateName: values.name,
      buttonValues: values.buttons
    });
    
    const formikErrors: any = {};
    if (!values.name) {
      formikErrors["name"] = `Template name is required`;
    }
    if (!values.allowCategoryChange) {
      formikErrors["allowCategoryChange"] = `Please select`;
    }
    if (!values.language) {
      formikErrors["language"] = `Please select language`;
    }
    if (!values.category) {
      formikErrors["category"] = `Please select category`;
    }
    if (!values.header) {
      formikErrors["header"] = `Required`;
    }
    if (!bodyValue.trim()) {
      formikErrors["body"] = `Required`;
    }
  
    if (values.haveButtons === "yes") {
      // First button validation
      if (!values.buttons[0].type) {
        formikErrors["buttons.0.type"] = `Required`;
      }
      if (!values.buttons[0].text) {
        formikErrors["buttons.0.text"] = `Required`;
      }
      if (values.buttons[0].type === "PHONE_NUMBER") {
        if (!values.buttons[0].phone_number?.trim()) {
          formikErrors["buttons.0.phone_number"] = `Required`;
        }
      } else if (values.buttons[0].type === "URL") {
        if (!values.buttons[0].url?.trim()) {
          formikErrors["buttons.0.url"] = `Required`;
        } else {
          let urlToCheck = values.buttons[0].url.trim();
          if (urlToCheck.startsWith('www.')) {
            urlToCheck = 'https://' + urlToCheck;
          }
          if (!/^https?:\/\//i.test(urlToCheck)) {
            urlToCheck = 'https://' + urlToCheck;
          }
          if (!isValidUrl(urlToCheck)) {
            formikErrors["buttons.0.url"] = `Please enter a valid URL`;
          }
        }
      }
  
      // Second button validation
      if (!values.buttons[1].type) {
        formikErrors["buttons.1.type"] = `Required`;
      }
      if (!values.buttons[1].text) {
        formikErrors["buttons.1.text"] = `Required`;
      }
      if (values.buttons[1].type === "PHONE_NUMBER") {
        if (!values.buttons[1].phone_number?.trim()) {
          formikErrors["buttons.1.phone_number"] = `Required`;
        }
      } else if (values.buttons[1].type === "URL") {
        if (!values.buttons[1].url?.trim()) {
          formikErrors["buttons.1.url"] = `Required`;
        } else {
          let urlToCheck = values.buttons[1].url.trim();
          if (urlToCheck.startsWith('www.')) {
            urlToCheck = 'https://' + urlToCheck;
          }
          if (!/^https?:\/\//i.test(urlToCheck)) {
            urlToCheck = 'https://' + urlToCheck;
          }
          if (!isValidUrl(urlToCheck)) {
            formikErrors["buttons.1.url"] = `Please enter a valid URL`;
          }
        }
      }
    }
  
    return formikErrors;
  }}
          onSubmit={(values) => {
            console.log("Form submission values:", {
              templateName: values.name,
              bodyValue: bodyValue
            });
            toggleConfirmEditModal();
            setFormValue({ ...values, body: bodyValue });
            
          }}
          
        >
          {({ values, errors, handleChange, handleSubmit }) => {
            const errorsObj: any = JSON.parse(JSON.stringify(errors));

            return (
              <form onSubmit={handleSubmit} className="create_template">
                <section className="auto-section-1 px-0">
  <div className="d-flex justify-content-between">
    <div className="align-items-center auto-section-1-sub-1">
      <button
        onClick={(e) => {
          e.preventDefault();
          toggleEditModal();
        }}
      >
        <i className="fa-regular fa-chevron-left"></i>
      </button>
      <h5 className="fw-bold text-dark fm-gilroyBold">
        Create Template
      </h5>
    </div>
    <button
      className="btn fw-bold auto_btn w-auto border-0 px-4"
      type="submit"
    >
      Submit for approval
    </button>
  </div>
</section>
                <div className="row mb-4">
                  <div className="col-md-6">
                    <Inputs
                      disabled={disabled}
                      name="name"
                      type="text"
                      autoComplete="off"
                      className="form-control py-2 h-45 text-lowercase"
                      placeholder="Enter template name"
                      value={values.name}
                      onChange={(e) => {
    console.log("Template name onChange:", {
      inputValue: e.target.value,
      inputType: typeof e.target.value
    });
    handleChange(e);
  }}
                      label="Template Name"
                      id=""
                      isRequired
                      options={[]}
                      des="template name only includes small letter & underscore (_)"
                    />
                    {errorsObj["name"] && (
                      <ErrorMessage name="name" component={Error} />
                    )}
                  </div>
                </div>
                <div className="row mb-4">
  <div className="col-md-4">
    <label className="form-label d-flex align-items-center justify-content-between">
      <p className="mb-0">
        Template Category <span className="text-danger">*</span>
      </p>
      <MyToolTip
        id={`categoryTooltip`}
        text={`Categories Available:\nAuthentication:For messages related to user verification, such as OTPs or login confirmations.\nMarketing:For promotional messages like discounts, offers, and marketing campaigns.\nUtility:For informational updates, alerts, reminders, and customer support.`}
      >
        <i className="lh-normal text-muted fs-5 ri-information-fill me-2"></i>
      </MyToolTip>
    </label>
    <select
      value={values.category}
      name="category"
      onChange={handleChange}
      className="form-select py-2 h-45"
    >
      <option value="" disabled>Select Category</option>
      <option value="AUTHENTICATION">Authentication</option>
      <option value="MARKETING">Marketing</option>
      <option value="UTILITY">Utility</option>
    </select>
    {errorsObj["category"] && (
      <ErrorMessage name="category" component={Error} />
    )}
  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Language <span className="text-danger">*</span>
                    </label>

                    <select
                      value={values.language}
                      name="language"
                      onChange={handleChange}
                      className="form-select py-2 h-45"
                    >
                      <option value="" disabled>
                        Select Language
                      </option>
                      {Object.keys(languageObj || {}).map(
                        (item: string, key: number) => (
                          <option key={key} value={item}>
                            {languageObj &&
                              (languageObj[item as keyof typeof languageObj] ||
                                "")}
                          </option>
                        )
                      )}

                      <option value="en_GB">English(GB)</option>
                    </select>
                    {errorsObj["language"] && (
                      <ErrorMessage name="language" component={Error} />
                    )}
                  </div>
                  <div className="col-md-4">
                    <label className="form-label d-flex align-items-center justify-content-between">
                      <p className="mb-0">
                        Allow category change{" "}
                        <span className="text-danger">*</span>
                      </p>
                      <MyToolTip
                        id={`AllowcategoryTooltip`}
                        text={
                          "Enable this option to allow the WhatsApp team to auto-change the category of the template if they determine that your message better fits another category. This increases the chances of template approval by ensuring it aligns with WhatsApp’s policies."
                        }
                      >
                        <i className="lh-normal text-muted fs-5 ri-information-fill me-2"></i>
                      </MyToolTip>
                    </label>
                    <select
                      value={values.allowCategoryChange}
                      name="allowCategoryChange"
                      onChange={handleChange}
                      className="form-select py-2 h-45"
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                    {errorsObj["allowCategoryChange"] && (
                      <Error>{errorsObj["allowCategoryChange"]}</Error>
                    )}
                  </div>
                </div>

                <div className="row">
  <div className="col-md-8">
                    {/* Header Section */}
                    <HeaderSection
                      expandedSections={expandedSections}
                      toggleSection={toggleSection}
                      values={values}
                      handleChange={handleChange}
                      setFile={setFile}
                      errorsObj={errorsObj}
                      fileName={fileName}
                      handleFileChange={handleFileChange}
                    />

                    <BodySection
                      expandedSections={expandedSections}
                      toggleSection={toggleSection}
                      bodyValue={bodyValue}
                      setBodyValue={setBodyValue}
                      values={values}
                      errorsObj={errorsObj}
                      handleChange={handleChange}
                    />

<ButtonSection
  toggleSection={toggleSection}
  values={values}
  errorsObj={errorsObj}
  handleChange={handleChange}
  expandedSections={expandedSections}
  setFieldValue={handleSetFieldValue}
  isSubmitting={isSubmitting}
/>
                  
                 
  </div>
  <div className="col-md-4">
    <WhatsAppPreview
      bodyValue={bodyValue}
      values={values}
      file={file}
    />
  </div>
  </div>
                
              </form>
            );
          }}
        </Formik>
        <DrawerExistModal
          showExitModal={showExitModal}
          title="Are you sure you want to exit ?"
          toggleEditModal={toggleEditModal}
          handleDrawerClick={handleDrawerClick}
        />
        <DrawerExistModal
          title="Are you sure you want submit?"
          showExitModal={confirmShowExitModal}
          toggleEditModal={toggleConfirmEditModal}
          handleDrawerClick={handleConfirmDrawerClick}
        />
      </div>
    </Spin>
  );
};
export default CreateTemplate;

export const Error = styled.p`
  color: red;
  margin-left: 4px;
  margin-top: 4px;
  font-size: 12px;
`;


