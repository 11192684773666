import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import GlobalDrawer from "../../components/GlobalDrawer";
import { toast } from 'react-hot-toast';
import { Eye, Save, X, Mail, Paperclip } from 'lucide-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import type { ChangeEvent, MouseEvent as ReactMouseEvent } from 'react';

interface EmailData {
    title: string;
    tags: string[];
    subject: string;
    message: string;
    type?: string; // Added type as an optional property
  }

interface Tag {
  name: string;
  value: string;
}

interface EmailDashboardProps {
    openEmailBox: boolean;
    setOpenEmailBox: React.Dispatch<React.SetStateAction<boolean>>;
    activeId: string;
    handleFormSubmit: (data: EmailData) => Promise<void>;
    messageTag: Tag[];
    initialData: EmailData;
  }

  const EmailDashboard: React.FC<EmailDashboardProps> = ({
    openEmailBox,
    setOpenEmailBox,
    activeId,
    handleFormSubmit,
    messageTag,
    initialData
  }) => {
    const [emailData, setEmailData] = useState<EmailData>(initialData);
    const [showPreview, setShowPreview] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
  
    useEffect(() => {
      setEmailData(initialData);
    }, [initialData]);
  
    const handleInputChange = useCallback((key: keyof EmailData, value: string | string[]) => {
      setEmailData(prev => ({ ...prev, [key]: value }));
    }, []);
  
    const handleTagToggle = useCallback((tagValue: string) => {
      setEmailData(prev => ({
        ...prev,
        tags: [tagValue] // Replace existing tags with only the selected tag
      }));
    }, []);
  
    const handleSave = useCallback(async () => {
      if (!emailData.title.trim()) {
        toast.error('Title is required.');
        return;
      }
      
      if (!emailData.subject.trim()) {
        toast.error('Subject is required.');
        return;
      }
      
      if (!emailData.message.trim()) {
        toast.error('Email content is required.');
        return;
      }
    
      setIsSubmitting(true);
      try {
        const dataToSubmit: EmailData = {
          ...emailData,
          type: 'email_template'
        };
        
        await handleFormSubmit(dataToSubmit);
        
        toast.success('Email template saved successfully!');
        setOpenEmailBox(false);
      } catch (error) {
        console.error('Error saving template:', error);
        if (error instanceof Error) {
          toast.error(`Failed to save email template: ${error.message}`);
        } else {
          toast.error('Failed to save email template. Please try again.');
        }
      } finally {
        setIsSubmitting(false);
      }
    }, [emailData, handleFormSubmit, setOpenEmailBox]);
  
    const modules = {
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        ['link'],
      ],
    };

    return (
        <GlobalDrawer
          openWithHeader={openEmailBox}
          setOpenWithHeader={setOpenEmailBox}
          drawerTitle={activeId ? 'Edit Email Template' : "Add Email Template"}
          size="lg"
        >
          <StyledEmailEditor>
            <Header>
              <h2>Email Template Editor</h2>
              <ButtonGroup>
                <Button onClick={() => setShowPreview(true)}><Eye size={16} /> Preview</Button>
                <Button primary onClick={handleSave} disabled={isSubmitting}>
                  {isSubmitting ? 'Saving...' : (
                    <>
                      <Save size={16} /> Save Template
                    </>
                  )}
                </Button>
              </ButtonGroup>
            </Header>
            <Content>
              <Input
                type="text"
                placeholder="Title"
                value={emailData.title}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange('title', e.target.value)}
              />
              <TagContainer>
  {messageTag.map((tag) => (
    <Tag
      key={tag.value}
      active={emailData.tags[0] === tag.value}
      onClick={() => handleTagToggle(tag.value)}
    >
      {tag.name.toLowerCase().replace(/_/g, ' ')} {/* Convert "no_tag" to "no tag" */}
    </Tag>
  ))}
</TagContainer>
              <Input
                type="text"
                placeholder="Subject"
                value={emailData.subject}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange('subject', e.target.value)}
              />
              <StyledReactQuill
                value={emailData.message}
                onChange={(content: string) => handleInputChange('message', content)}
                modules={modules}
                placeholder="Compose your email..."
              />
              <AttachmentButton>
                <Paperclip size={16} />
                Attach File
              </AttachmentButton>
            </Content>
          </StyledEmailEditor>
          {showPreview && (
            <PreviewModalOverlay onClick={() => setShowPreview(false)}>
              <PreviewModal onClick={(e: ReactMouseEvent<HTMLDivElement>) => e.stopPropagation()}>
  <PreviewHeader>
    <h3>Email Preview</h3>
    <CloseButton onClick={() => setShowPreview(false)}><X size={20} /></CloseButton>
  </PreviewHeader>
  <PreviewContent>
    <h4>Subject: {emailData.subject}</h4>
    <div dangerouslySetInnerHTML={{ __html: emailData.message }} />
  </PreviewContent>
</PreviewModal>
            </PreviewModalOverlay>
      )}
    </GlobalDrawer>
  );
};

const StyledEmailEditor = styled.div`
  font-family: 'Inter', sans-serif;
  background-color: #f8f9fa;
  border-radius: 8px;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-bottom: 1px solid #e9ecef;

  h2 {
    margin: 0;
    font-size: 18px;
    color: #212529;
    font-weight: 600;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const Button = styled.button<{ primary?: boolean }>`
  display: flex;
  align-items: center;
  padding: 6px 10px;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  ${props => props.primary ? `
    background-color: #3498db;
    color: white;
    &:hover {
      background-color: #2980b9;
    }
    svg {
      color: white;
    }
  ` : `
    background-color: #e9ecef;
    color: #495057;
    &:hover {
      background-color: #dee2e6;
    }
  `}

  svg {
    margin-right: 6px;
  }
`;

const Content = styled.div`
  padding: 15px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.2s ease;

  &:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 0 0 2px rgba(52,152,219,0.2);
  }
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-bottom: 12px;
`;

const Tag = styled.span<{ active: boolean }>`
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 11px;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: ${props => props.active ? '#3498db' : '#e9ecef'};
  color: ${props => props.active ? 'white' : '#495057'};
  text-transform: capitalize; // This will capitalize the first letter
  margin: 2px;
  font-weight: 500;

  &:hover {
    background-color: ${props => props.active ? '#2980b9' : '#dee2e6'};
  }
`;

const Tabs = styled.div`
  display: flex;
  background-color: #fff;
  border-bottom: 1px solid #e9ecef;
  margin-bottom: 12px;
`;

const Tab = styled.button<{ active: boolean }>`
  display: flex;
  align-items: center;
  padding: 10px 14px;
  border: none;
  background-color: transparent;
  color: ${props => props.active ? '#3498db' : '#6c757d'};
  font-weight: ${props => props.active ? '600' : '400'};
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  border-bottom: 2px solid ${props => props.active ? '#3498db' : 'transparent'};

  &:hover {
    color: #3498db;
  }

  svg {
    margin-right: 8px;
  }
`;

const StyledReactQuill = styled(ReactQuill)`
  .ql-container {
    min-height: 200px;
    font-size: 14px;
  }
  .ql-editor {
    min-height: 200px;
    max-height: 400px;
    overflow-y: auto;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 6px;
  font-size: 14px;
  resize: vertical;
  min-height: 200px;
  max-height: 400px;
  transition: border-color 0.2s ease;

  &:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 0 0 2px rgba(52,152,219,0.2);
  }
`;

const PreviewModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const PreviewModal = styled.div`
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const PreviewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 18px;
  border-bottom: 1px solid #e9ecef;

  h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }
`;

const PreviewDeviceToggle = styled.div`
  display: flex;
  gap: 8px;
`;

const DeviceButton = styled.button<{ active: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  color: ${props => props.active ? '#3498db' : '#6c757d'};
  transition: color 0.2s ease;

  &:hover {
    color: #3498db;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #6c757d;
  transition: color 0.2s ease;

  &:hover {
    color: #343a40;
  }
`;
const AttachmentButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 8px 12px;
  color: #495057;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: #f8f9fa;
  }
`;
const PreviewContent = styled.div`
  padding: 18px;

  h4 {
    margin-top: 0;
    margin-bottom: 14px;
    font-size: 16px;
    font-weight: 500;
  }
`;

export default EmailDashboard;