export const taskOptions = [
  {
    name: "Call",
    value: "call",
  },
  {
    name: "Email",
    value: "email",
  },
  {
    name: "Message",
    value: "message",
  },
  {
    name: "Follow Up",
    value: "follow_up",
  },
  {
    name: "Send",
    value: "send",
  },
  {
    name: "Video Call",
    value: "video_call",
  },
];

export const repeatOptions = [
  {
    name: "Everyday",
    value: "everyday",
  },
  {
    name: "Weekly",
    value: "weekly",
  },
  {
    name: "Monthly",
    value: "monthly",
  },
  {
    name: "Yearly",
    value: "yearly",
  },
  {
    name: "Don't Repeat",
    value: "NoRepeat",
  },
];

export const taskDueDates = [
  {
    name: "Today",
    value: "today",
  },
  {
    name: "Tomorrow",
    value: "tomorrow",
  },
  {
    name: "3 Days From Now",
    value: "3d",
  },
  {
    name: "1 Week From Now",
    value: "1w",
  },
  {
    name: "1 Month From Now",
    value: "1m",
  },
  {
    name: "Custom Date",
    value: "custom",
  },
];

export const taskDueDatesValues = {
  today: "0",
  tomorrow: "1",
  "3d": "3",
  "1w": "7",
  "1m": "30",
};

export const dashboardDates = [
  { name: "Today", value: "today" },
  { name: "Yesterday", value: "yesterday" },
  { name: "This Week", value: "thisWeek" },
  { name: "Last Week", value: "lastWeek" },
  { name: "This Month", value: "thisMonth" },
  { name: "Last Month", value: "lastMonth" },
  { name: "This Quarter", value: "thisQuarter" },
  { name: "Last Quarter", value: "lastQuarter" },
  { name: "This Year", value: "thisYear" },
  { name: "Last 7 Days", value: "7d" },
  { name: "Last 15 Days", value: "15d" },
  { name: "Last 30 Days", value: "30d" },
  { name: "Custom", value: "custom" }
];

export const automationActionData = [
  {
    name: "Create task",
    value: "create_task",
    isSelected: false,
  },
  {
    name: "Change status",
    value: "status_change",
    isSelected: false,
  },
  {
    name: "Change Label",
    value: "label_change",
    isSelected: false,
  },
  {
    name: "Move to list",
    value: "move_to_list",
    isSelected: false,
  },
  {
    name: "Copy to list",
    value: "copy_to_list",
    isSelected: false,
  },
  {
    name: "Send notification",
    value: "push_notification",
    isSelected: false,
  },
  {
    name: "Assign lead",
    value: "assign_lead",
    isSelected: false,
  },
  {
    name: "Delay",
    value: "delay",
    isSelected: false,
  },
  {
    name: "Send Email",
    value: "send_email",
    isSelected: false,
  },
  {
    name: "Send Whatsapp",
    value: "send_whatsapp",
    isSelected: false,
  },
];

export const automationWhenTriggerData = [
  {
    name: "New lead is created",
    value: "create_lead",
  },
  {
    name: "Lead is edited",
    value: "edit_lead",
  },
  {
    name: "Status is updated",
    value: "status_change",
  },
  {
    name: "Label is updated",
    value: "label_change",
  },
  {
    name: "Page is viewed",
    value: "view_page",
  },
  {
    name: "File is viewed",
    value: "view_file",
  },
  {
    name: "Quotation created",
    value: "create_quotation",
  },
  {
    name: "Quotation edited",
    value: "edit_quotation",
  },
  {
    name: "Create task",
    value: "create_task",
  },
  {
    name: "Task is edited",
    value: "edit_task",
  },
  {
    name: "Activity is created",
    value: "create_activity",
  },
  {
    name: "Incoming call activity",
    value: "create_incoming_activity",
  },
  {
    name: "Outgoing call activity",
    value: "create_outgoing_activity",
  },
  {
    name: "Missed call activity",
    value: "create_miscalled_activity",
  },
  {
    name: "New lead added to list",
    value: "new_lead_added",
  },
];

export const customActivityType = [
  {
    name: "Incoming",
    value: "INCOMING",
  },
  {
    name: "Outgoing ",
    value: "OUTGOING",
  },
  {
    name: "Missed",
    value: "MISSED",
  },
  {
    name: "share",
    value: "share",
  },
  {
    name: "Viewed",
    value: "viewed",
  },
  {
    name: "Status Update",
    value: "status_updated",
  },
  {
    name: "Task Created",
    value: "task_created",
  },
  {
    name: "Task Updated",
    value: "task_updated",
  },
  {
    name: "Task Assigned",
    value: "task_assigned",
  },
  {
    name: "Task Completed",
    value: "task_completed",
  },
  {
    name: "Auto lead assigned",
    value: "auto_lead_assign",
  },
  {
    name: "Note Created",
    value: "note_created",
  },
  {
    name: "Quotation created",
    value: "quotation_created",
  },
  {
    name: "Quotation approved",
    value: "quotation_approved",
  },
  {
    name: "Lead copied",
    value: "lead_copied",
  },
  {
    name: "Lead moved",
    value: "lead_moved",
  },
  {
    name: "Lead details updated",
    value: "lead_details_updated",
  },
  {
    name: "Voice Note",
    value: "new_voice_note",
  },
  {
    name: "Lead score update",
    value: "lead_score_update",
  },
];
