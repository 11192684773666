import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header";
import { Toaster } from "react-hot-toast";
import { Spin } from "antd";
import "./integrations.css";
import { getIntegrations } from "../../services/integrations";
import { typeFormat } from "../../utils/helpers";
import { useSelector } from "react-redux";
import { FaCheckCircle } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleCalendarIntegration from "./GoogleCalendarIntegration";
import EmptyState from './EmptyState';
//import GoogleMailIntegration from './GoogleMailIntegration';

const GOOGLE_CLIENT_ID = "322938904388-p7l4fso2n9g93n8irg3c9get69rn3vk4.apps.googleusercontent.com";
const Integrations: React.FC = () => {
  const iframeRef = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const [integrationsList, setIntegrationsList] = useState<any[]>([]);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [queyParams, setQueryParams] = useState<string>("");
  const [integrationMsg, setIntigrationMsg] = useState<string>("");

  // Add Google Calendar as a hardcoded integration
  const googleCalendarIntegration = {
    _id: "google_calendar",
    key: "google_calendar",
    name: "Google Calendar",
    isDisplayable: true,
    isActive: true
  };

  //const googleMailIntegration = {
  //  _id: "google_mail",
  //  key: "google_mail",
  //  name: "Google Mail",
  //  isDisplayable: true,
  //  isActive: true
  //};
  
  const allIntegrations = [
    ...useSelector(
      (state: any) =>
        state?.rootReducers?.userData?.userDetails?.systemIntegration?.filter(
          (_i: { isDisplayable: any; isActive: any }) =>
            _i.isDisplayable && _i.isActive
        ) || []
    ),
    googleCalendarIntegration
    //googleMailIntegration
  ];

  const user = useSelector(
    (state: any) => state?.rootReducers?.userData?.userDetails || ""
  );

  const fetchIntegrations = async () => {
    setLoading(true);
    const { data } = await getIntegrations();
    setIntegrationsList(data?.data?.length ? data.data : []);
    setLoading(false);
  };

  useEffect(() => {
    fetchIntegrations();
  }, []);

  useEffect(() => {
    if (selectedOption) {
      window.scrollTo(0, 0);
    }
  }, [selectedOption]);

  const selectUserIntegration = (integrationId: string) => {
    return (
      integrationsList?.find((_i) => _i.integrationId === integrationId) || null
    );
  };

  const facebookLogin = () => {
    if (window.FB) {
      window.FB.getLoginStatus((response: any) => {
        if (response.status === "connected") {
          handleLogin(response.authResponse);
        } else {
          window.FB.login(
            (response: any) => {
              if (response.status === "connected") {
                handleLogin(response.authResponse);
              } else {
                alert("Login cancelled or failed");
              }
            },
            {
              scope:
                "public_profile,email,pages_show_list,pages_read_engagement,pages_manage_metadata,pages_manage_ads,ads_management,leads_retrieval,business_management",
            }
          );
        }
      });
    }
  };

  const handleLogin = (authResponse: any) => {
    const { accessToken, userID } = authResponse;
    fetch(`https://graph.facebook.com/${userID}?access_token=${accessToken}`)
      .then((response) => response.json())
      .then((data) => {
        setQueryParams(`accessToken=${accessToken}&fb_user_id=${userID}`);
      })
      .catch((error) => {
        alert("An error occurred: " + error.message);
      });
  };

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (
        event?.data?.message === "disconnect" ||
        event?.data?.message === "connected"
      ) {
        setSelectedOption(null);
        fetchIntegrations();
        setQueryParams("");

        setIntigrationMsg(
          event?.data?.message === "disconnect"
            ? "Integration was disconnected successfully"
            : "Integration was completed successfully"
        );

        fetchIntegrations();
      }

      if (event?.data?.message === "facebook") {
        facebookLogin();
      }
    };

    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const renderIntegrationContent = () => {
    //if (selectedOption?.key === 'google_mail') {
    //  return (
    //    <GoogleMailIntegration
    //      token={user?.token}
    //      userId={user?._id}
    //      onSuccess={() => {
    //        setIntigrationMsg("Gmail connected successfully");
    //        fetchIntegrations();
    //      }}
    //      onError={(error) => {
    //        setIntigrationMsg(`Failed to connect Gmail: ${error}`);
    //      }}
    //    />
    //  );
    //}
  
    if (selectedOption?.key === 'google_calendar') {
      return (
        <GoogleCalendarIntegration
          token={user?.token}
          userId={user?._id}
          onSuccess={() => {
            setIntigrationMsg("Calendar connected successfully");
            fetchIntegrations();
          }}
          onError={(error) => {
            setIntigrationMsg(`Failed to connect calendar: ${error}`);
          }}
        />
      );
    }
  
    if (selectedOption?.key) {
      return (
        <section className="webview_section expanded">
          <div className="webview_container">
            <iframe
              frameBorder={0}
              id="integration"
              ref={iframeRef}
              className="iframe_container"
              src={`https://integration.3sigmacrm.com/integration/${
                selectedOption?.key
              }/${user?._id}?token=${user?.token?.slice(7)}${
                queyParams ? `&${queyParams}` : ""
              }`}
            />
          </div>
        </section>
      );
    }
  
    if (integrationMsg) {
      return (
        <p className="m-auto" style={{ fontSize: 18 }}>
          <strong>{integrationMsg}</strong>
        </p>
      );
    }
  
    return <EmptyState />;
  };

  return (
    <div id="main" className="main expanded-view" style={{ paddingTop: 0 }}>
      <Header />
      <section className="auto-section-1">
        <div className="d-flex justify-content-between">
          <div className="align-items-center auto-section-1-sub-1">
            <label className="title_text">Integrations</label>
          </div>
        </div>
      </section>

      <Spin size="large" tip="Loading..." spinning={false}>
        <div style={{ display: "flex", flex: 1 }}>
          <section className="auto-section-2 inegrations_section">
            {allIntegrations?.map((item: any, i: number) => {
              const isSelected = item?.key === selectedOption?.key;
              const userIntegration = item.key === 'google_calendar'
                ? selectUserIntegration(item._id)
                : selectUserIntegration(item._id);
              
              return (
                <div
                  key={i}
                  onClick={() => {
                    setSelectedOption(item);
                    setIntigrationMsg("");
                  }}
                  className={`auto-section-3-toggle integration_body ${
                    isSelected ? "selected" : ""
                  }`}
                  style={{ width: "200px" }}
                >
                  <div className="inegrations_container">
                  {item.key === 'google_calendar' ? (
                    <img 
                      src={require('../../assets/images/integration/googlecalender.png')}
                      alt="Google Calendar"
                      style={{
                        marginRight: 10,
                        width: '20px',
                        height: '20px'
                      }}
                    />
                  ) : (
                    typeFormat(item?.key).icon
                  )}
                    <h6
                      className="option_text"
                      style={{ color: isSelected ? "#3FAEFD" : "#000" }}
                    >
                      {item.name}
                    </h6>
                  </div>
                  {userIntegration && <FaCheckCircle size={15} color="green" />}
                </div>
              );
            })}
          </section>

          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            {renderIntegrationContent()}
          </GoogleOAuthProvider>
        </div>
      </Spin>

      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default Integrations;