import styles from "./ultimatePlan.module.scss";

const UltimatePlanInfo = () => {
  return (
    <div className={styles.ultimate_container}>
      <h5>You are currently not on ultimate plan.</h5>
      <a target="_blank" href="https://wa.me/918814048362">
        Get help!
      </a>
    </div>
  );
};

export default UltimatePlanInfo;
