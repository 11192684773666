import React from "react";
import styled from "styled-components";
import facebook from "../../../assets/images/facebook-logo.svg";
import nineacres from "../../../assets/images/integration/99acres.png";
import excel from "../../../assets/images/integration/excel.png";
import googleforms from "../../../assets/images/integration/googleforms.png";
import housing from "../../../assets/images/integration/housing.jpg";
import indiamart from "../../../assets/images/integration/indiamart.png";
import justdial from "../../../assets/images/integration/justdial.png";
import leadform from "../../../assets/images/integration/leadform.png";
import magicbricks from "../../../assets/images/integration/magicbricks.png";
import tradeindia from "../../../assets/images/integration/tradeIndia.png";
import typeform from "../../../assets/images/integration/typeform.png";
import word from "../../../assets/images/integration/word.png";
import wordpress from "../../../assets/images/integration/wordpress.png";
import zapiuer from "../../../assets/images/integration/zapiuer.svg";
import customform from "../../../assets/images/integration/Custom Form.jpeg";
import LeadLabel from "../../../components/leadLabel";
import LeadStatus from "../../../components/leadStatus";
import ContactLinksGenerator from "../../../utils/contactlinksgenerator";
import moment from "moment";
import "../leads.css";
import { addCountryCode } from "../../../utils/helpers";

const StyledImg = styled.img`
  margin-right: 5px;
`;

const nameRenderer: React.FC<any> = (props) => {
  const { value } = props;
  if (!value) {
    return <span>-</span>;
  }
  return (
    <div className="h-100 align-items-center d-flex">
      <div className="lead_cell">
        <div className="cell_label" title={value}>
          {/* {(value || "").length > 16
            ? value.substr(0, 16).concat("...")
            : value} */}
          {value}
        </div>
      </div>
    </div>
  );
};

const phoneRenderer: React.FC<any> = (props) => {
  const { value, data } = props;
  if (!value || !data) {
    return <span>-</span>;
  }
  const { email, phone } = data;
  const contactObj = new ContactLinksGenerator({
    number: addCountryCode(phone),
    email: email,
  });

  return (
    <div className="h-100 align-items-center d-flex">
      <div className="leads-section-3-sub-1-2">
        <ul className="mb-0">
          <li>
            {value ? (
              <a onClick={(e) => e.stopPropagation()} href={contactObj.tel}>
                <img alt="tel" src="assets/img/telephone.png" />
              </a>
            ) : (
              <a onClick={(e) => e.stopPropagation()}>
                <img alt="tel" src="assets/img/greyPhone.png" />
              </a>
            )}
          </li>
          <li>
            {email ? (
              <a onClick={(e) => e.stopPropagation()} href={contactObj.mail}>
                <img alt="mail" src={"assets/img/email.png"} />
              </a>
            ) : (
              <a onClick={(e) => e.stopPropagation()}>
                <img alt="mail" src={"assets/img/greyEmail.png"} />
              </a>
            )}
          </li>
          <li>
            <a
              onClick={(e) => e.stopPropagation()}
              href={contactObj.whatsapp}
              target="_blank"
            >
              <img alt="wp" src="assets/img/whatsapp.png" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

const statusRenderer: React.FC<any> = (props) => {
  const { value, preferences } = props;
  if (!value || !preferences) {
    return <span>-</span>;
  }

  return (
    <div className="d-flex align-items-center h-100">
      <div className="lead-subtext">
        <LeadStatus preferences={preferences} status={value} isTag={true} />
      </div>
    </div>
  );
};

const labelRenderer: React.FC<any> = (props) => {
  const { value, preferences } = props;
  if (!value || !preferences) {
    return <span>-</span>;
  }

  return (
    <div className="d-flex align-items-center h-100">
      <div className="lead-subtext">
        <LeadLabel preferences={preferences} label={value} isTag={true} />
      </div>
    </div>
  );
};

const sourceRenderer: React.FC<any> = (props) => {
  const { value, data } = props;
  if (!value || !data) {
    return <span>-</span>;
  }
  const { customSource } = data;
  const imageMap: { [key: string]: string } = {
    "99Acres": nineacres,
    CSV: excel,
    "Google spread sheets": googleforms,
    Housing: housing,
    Indiamart: indiamart,
    "Just Dial": justdial,
    "Magic Bricks": magicbricks,
    Tradeindia: tradeindia,
    "Type Form": typeform,
    Word: word,
    Wordpress: wordpress,
    Zapier: zapiuer,
    Facebook: facebook,
    "Lead Form": leadform,
    "Custom Form": customform,
  };
  const imageUrl =
    imageMap[typeof value === "string" ? value : value?.name || customSource];
  return (
    <div className="h-100 align-items-center d-flex">
      <div className="lead_cell">
        <div className="cell_label d-flex align-items-center" title={value}>
          <StyledImg src={imageUrl} height="12" width="12" />
          <span>{typeof value === "string" ? value : value?.name || ""}</span>
        </div>
      </div>
    </div>
  );
};

const lastActivityRenderer: React.FC<any> = (props) => {
  const { value, data } = props;
  if (!value || !data) {
    return <span>-</span>;
  }
  const { lastActivityType } = data;
  const time = moment(value);
  return (
    <div className="h-100 align-items-center d-flex">
      <div className="lead_cell">
        <div className="cell_label" title={value}>
          {lastActivityType} {value ? time.fromNow() : ""}
        </div>
      </div>
    </div>
  );
};

const assignedToRenderer: React.FC<any> = (props) => {
  const { value } = props;
  if (!value) {
    return <span>-</span>;
  }
  return value ? (
    <div className="d-flex align-items-center h-100">
      <div className="lead-subtext">
        <div
          style={{
            backgroundColor: "#2298FF",
            color: "#FFF",
            borderRadius: "10px",
            fontFamily: "Gilroy-Regular",
          }}
          className="cell_label p-1 px-2 fitContent"
        >
          {value?.firstName
            ? value?.firstName
            : "" + " " + value?.lastName
            ? value?.lastName
            : ""}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

const locationRenderer: React.FC<any> = (props) => {
  const { value } = props;
  if (!value) {
    return <span>-</span>;
  }
  return (
    <div className="h-100 align-items-center d-flex">
      <div className="lead_cell">
        <div className="cell_label" title={value}>
          {value.address}
        </div>
      </div>
    </div>
  );
};

const dateTimeRenderer: React.FC<any> = (props) => {
  const { value } = props;
  if (!value) {
    return <span>-</span>;
  }
  let dateFormat = "DD MMM YYYY";
  if (moment(value).format("HH:mm:ss") !== "00:00:00") {
    dateFormat += ", hh:mm a";
  }
  const formattedDate = moment(value).format(dateFormat);
  return (
    <div className="h-100 align-items-center d-flex">
      <div className="lead_cell">
        <div className="cell_label" title={formattedDate}>
          {formattedDate}
        </div>
      </div>
    </div>
  );
};

const dateRenderer: React.FC<any> = (props) => {
  const { value } = props;
  if (!value) {
    return <span>-</span>;
  }
  let dateFormat = "DD MMM YYYY";
  const formattedDate = moment(value).format(dateFormat);
  return (
    <div className="h-100 align-items-center d-flex">
      <div className="lead_cell">
        <div className="cell_label" title={formattedDate}>
          {formattedDate}
        </div>
      </div>
    </div>
  );
};

const amountRenderer: React.FC<any> = (props) => {
  const { value } = props;
  if (!value) {
    return <span>-</span>;
  }
  return (
    <div className="h-100 align-items-center d-flex">
      <div className="lead_cell">
        <div className="cell_label">{`₹ ${value.toLocaleString("en-IN")}`}</div>
      </div>
    </div>
  );
};

export {
  labelRenderer,
  nameRenderer,
  phoneRenderer,
  sourceRenderer,
  statusRenderer,
  lastActivityRenderer,
  assignedToRenderer,
  locationRenderer,
  dateRenderer,
  amountRenderer,
  dateTimeRenderer,
};
