import React, { FC, useEffect, useState } from "react";
import { getLeadDetail } from "../../services/leadService";
import { useSelector } from "react-redux";
import { MdOutlineEdit } from "react-icons/md";

interface InformationProps {
  leadToBeUpdate: string;
  onEdit?: () => void;
}

const Information: FC<InformationProps> = ({ leadToBeUpdate, onEdit }) => {
  const stateData = useSelector((state: any) => state?.rootReducers);
  const [leadData, setLeadData] = useState<Record<string, any>>({});
  const [customFields, setCustomFields] = useState<Record<string, any>>({});

  useEffect(() => {
    fetchLeadDetails();
  }, []);

  const fetchLeadDetails = async () => {
    try {
      const { data } = await getLeadDetail(leadToBeUpdate);
      const extraDetails = data.data.extraDetails || {};

      const filterValues = stateData?.userData?.userDetails?.userPreference?.customForm?.map(
        ({ value }: { value: string }) => value
      ) || [];

      const processedFields = processExtraDetails(extraDetails);

      const customFieldsData: Record<string, any> = {};
      const leadFieldsData: Record<string, any> = {};

      Object.entries(processedFields).forEach(([key, value]) => {
        const originalKey = key.toLowerCase().replace(/ /g, '_');
        if (filterValues.includes(originalKey)) {
          customFieldsData[key] = value;
        } else {
          leadFieldsData[key] = value;
        }
      });

      setLeadData(leadFieldsData);
      setCustomFields(customFieldsData);
    } catch (error) {
      console.error("Error fetching lead details:", error);
    }
  };

  const processExtraDetails = (details: Record<string, any>) => {
    const processed: Record<string, any> = {};

    Object.entries(details).forEach(([key, value]) => {
      const formattedKey = key
        .replace(/_/g, ' ')
        .replace(/\?$/, '')
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

      processed[formattedKey] = formatValue(value);
    });

    return processed;
  };

  const formatValue = (value: any): string => {
    if (value === null || value === undefined) return '-';
    if (Array.isArray(value)) return value.length ? value.join(', ') : '-';
    if (typeof value === 'boolean') return value ? 'Yes' : 'No';
    if (typeof value === 'number') return value.toLocaleString();
    return String(value) || '-';
  };

  const shouldDisplayField = (key: string): boolean => {
    const skipFields = ['latitude', 'longitude', 'latitudeDelta', 'longitudeDelta'];
    return !skipFields.includes(key.toLowerCase());
  };

  return (
    <div style={{ padding: "20px" }}>
      {/* General Fields */}
      <ul className="popup-section-4-left-2">
        {Object.entries(leadData).map(([key, value], index) => (
          shouldDisplayField(key) && (
            <li key={index} style={{ padding: "8px 0", marginBottom: "4px" }}>
              <span style={{ 
                display: "block", 
                color: "#666", 
                marginBottom: "4px",
                fontSize: "14px"
              }}>
                {key}
              </span>
              <div style={{ color: "#333" }}>{value}</div>
            </li>
          )
        ))}
      </ul>

      {/* Custom Fields Section */}
      {Object.keys(customFields).length > 0 && (
        <div style={{ marginTop: "24px" }}>
          <h5
            style={{
              fontSize: "16px",
              fontWeight: 500,
              color: "#333",
              borderBottom: "1px solid #e0e0e0",
              paddingBottom: "8px",
              marginBottom: "16px"
            }}
          >
            Leadform Fields
          </h5>

          <ul className="popup-section-4-left-2">
            {Object.entries(customFields).map(([key, value], index) => (
              <InfoList
                key={index}
                item={key}
                value={value}
                onEdit={onEdit}
              />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const InfoList: FC<{
  item: string;
  value: string;
  onEdit?: () => void;
}> = ({ item, value, onEdit }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <li
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        padding: "8px 0",
        marginBottom: "4px",
        position: "relative",
        cursor: "pointer",
        background: "white"
      }}
    >
      <span style={{ 
        display: "block", 
        color: "#666", 
        marginBottom: "4px",
        fontSize: "14px"
      }}>
        {item}
      </span>
      <div style={{ color: "#333" }}>{value}</div>

      {isHovered && (
        <div
          className="activity_edit_icon"
          style={{
            position: "absolute",
            right: "8px",
            top: "50%",
            transform: "translateY(-50%)",
            background: "#2196F3",
            borderRadius: "50%",
            padding: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <MdOutlineEdit
            color="#ffffff"
            size={16}
            onClick={onEdit}
            style={{ cursor: "pointer" }}
          />
        </div>
      )}
    </li>
  );
};

export default Information;