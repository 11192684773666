import React from 'react';
import { FaPlug } from 'react-icons/fa';
import styled from 'styled-components';

const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 2rem;
  text-align: center;
`;

const IconWrapper = styled.div`
  font-size: 4rem;
  color: #3FAEFD;
  margin-bottom: 1rem;
`;

const Title = styled.h2`
  font-family: 'Gilroy Bold', 'Arial', sans-serif;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
`;

const Description = styled.p`
  font-family: 'Gilroy', 'Arial', sans-serif;
  font-size: 1rem;
  color: #666;
  max-width: 600px;
  margin: 0 auto;
  white-space: normal;
`;

const EmptyState: React.FC = () => {
  return (
    <EmptyStateContainer>
      <IconWrapper>
        <FaPlug />
      </IconWrapper>
      <Title>No Integration Selected</Title>
      <Description>
        Integrations allow you to configure automatic import of leads, send emails, and WhatsApp messages. Select an integration to get started.
      </Description>
    </EmptyStateContainer>
  );
};

export default EmptyState;