import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import moment from "moment";
import styled from "styled-components";
import FormTable from "../userForm/FormTable";
import { IoIosSend } from "react-icons/io";
import { BsFillReplyAllFill } from "react-icons/bs";
import { MdOutlineSmsFailed } from "react-icons/md";
import { FaEye } from "react-icons/fa";

export interface CampaignFormData {
  _id: string;
  name: string;
  createdBy: {
    firstName: string;
    lastName?: string;
  };
  createdAt: string;
  totalSent: number;
  totalRead: number;
  totalReplied: number;
  totalFailed: number;
  totalLeads: number;
  status?: string; // Add any other required fields FormTable might need
}

interface CampaignFormProps {
  formDetail: CampaignFormData;
}

const CampaignForm: React.FC<CampaignFormProps> = ({
  formDetail,
}) => {
  const [formData, setFormData] = useState<CampaignFormData | null>(null);
  const [isPageLoad, setIsPageLoad] = useState<boolean>(false);

  useEffect(() => {
    let mounted = true;
    
    const updateFormData = () => {
      if (mounted && formDetail) {
        try {
          setIsPageLoad(true);
          // Only set form data if all required fields are present
          if (formDetail._id && formDetail.name) {
            setFormData(formDetail);
          }
        } catch (error) {
          console.error('Error loading campaign data');
        } finally {
          setIsPageLoad(false);
        }
      }
    };

    updateFormData();

    return () => {
      mounted = false;
    };
  }, [formDetail]);

  if (isPageLoad) {
    return (
      <Root>
        <Spin size="large" tip="Loading..." spinning={true}>
          <div className="mt-2">Loading...</div>
        </Spin>
      </Root>
    );
  }

  return (
    <Root>
      <Spin size="large" tip="Loading..." spinning={isPageLoad}>
        <div className="mt-2">
        <div className="row mb-3">
            <div>
            <CampaignName className="btn text-capitalize mb-1">
                {formData?.name || 'Untitled Campaign'}  
              </CampaignName>
              <SentBy>
                Sent by {formData?.createdBy?.firstName || 'Unknown'} on{' '}
                {formData?.createdAt ? moment(formData.createdAt).format("Do MMM YYYY") : 'Invalid Date'} at{' '}
                {formData?.createdAt ? moment(formData.createdAt).format("hh:mm A") : 'Invalid Time'}
              </SentBy>
            </div>
          </div>
          
          <div className="row mt-3">
          <div className="d-flex gap-3"> 
              <InfoButtons>
                <div>
                  <h5 className="alert-heading">Sent</h5>
                  <IoIosSend color="#0397FED1" style={{ marginLeft: 2 }} />
                </div>
                <h5 className="alert-heading">{formData?.totalSent ?? 0}</h5>
              </InfoButtons>
              <InfoButtons>
                <div>
                  <h5 className="alert-heading">Read</h5>
                  <FaEye color="#4CB050" />
                </div>
                <h5 className="alert-heading">{formData?.totalRead ?? 0}</h5>
              </InfoButtons>
              <InfoButtons>
                <div>
                  <h5 className="alert-heading">Replied</h5>
                  <BsFillReplyAllFill color="#774A4A91" />
                </div>
                <h5 className="alert-heading">{formData?.totalReplied ?? 0}</h5>
              </InfoButtons>
              <InfoButtons>
                <div>
                  <h5 className="alert-heading">Failed</h5>
                  <MdOutlineSmsFailed color="#ED0A0A" />
                </div>
                <h5 className="alert-heading">{formData?.totalFailed ?? 0}</h5>
              </InfoButtons>
            </div>
          </div>
          <hr className="divider my-2" />
          <ul className="nav justify-content-between d-flex mb-2">
            <li>
            <TotalSentButton className="px-3 py-1"> 
                Sent ({formData?.totalLeads ?? 0})
              </TotalSentButton>
            </li>
          </ul>
          {formData ? (
  <FormTable tableData={formData} />
) : (
  <div className="d-flex justify-content-center align-items-center p-4">
    <p>No data available</p>
  </div>
)}
        </div>
      </Spin>
    </Root>
  );
};

export default CampaignForm;

// Styled components remain exactly the same
const Root = styled.div`
  padding: 0 16px; // Reduced from 20px
`;

const CampaignName = styled.h4`
  font-size: 18px; // Reduced from 20px
  font-family: "Gilroy-Bold";
  padding: 0;
  margin: 0;
`;

const TotalSentButton = styled.button`
  font-family: "Gilroy-Semibold";
  background-color: #fff;
  font-size: 15px;
`;

const SentBy = styled.p`
  font-size: 11px; // Reduced from 12px
  font-family: "Gilroy-Semibold";
  float: right;
  margin-top: 8px; // Reduced from 12px
`;

const InfoButtons = styled.button`
  min-width: 120px; // Reduced from 130px
  background-color: #fff;
  height: 60px;  // Reduced from 70px
  border-radius: 8px; // Reduced from 10px
  padding: 8px; // Added compact padding


   div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px; // Added to reduce space between icon and number

    svg {
      margin-left: 4px;
      font-size: 16px; // Reduced from 18px
      margin-bottom: 0; // Removed bottom margin
    }
  }

  h5 {
    font-size: 14px; // Reduced from 16px
    color: #000;
    font-family: "Gilroy-Semibold";
    margin: 0; // Remove default margins
  }
`;