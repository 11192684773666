// MessageBubble.tsx
import React, { ReactNode } from 'react';
import { Anchorme } from "react-anchorme";
import moment from 'moment';
import { MessageMedia } from './MessageMedia';

// Define message type interface to match both text and media messages
interface Message {
  _id: string;
  sender: any;
  messageType?: 'text' | 'reaction' | 'video' | 'image' | 'document' | 'audio';
  downloadUrl?: string;
  message: {
    emoji?: string;
    body: string | { text?: string };
    id?: string;
    mime_type?: string;
  };
  createdAt: string;
  messageDelivery?: 'sent' | 'delivered' | 'read';
}

interface MessageBubbleProps {
  message: Message;
  currentUser: {
    firstName: string;
  };
  onMediaDownload: (info: Message) => Promise<void>;
  integrationInfo: any;
  isFirstInGroup?: boolean;
  isLastInGroup?: boolean;
  showTimestamp?: boolean;
  isLoading?: boolean;      // Add this
  downloadError?: string;
}

export const MessageBubble: React.FC<MessageBubbleProps> = ({
  message,
  currentUser,
  onMediaDownload,
  integrationInfo,
  isFirstInGroup = true,
  isLastInGroup = true,
  showTimestamp = true
}) => {
  const isOutgoing = message.sender !== null;

  // Format text content while preserving newlines and spaces
  const formatContent = (text: string): ReactNode[] => {
    if (!text) return [' ']; // Handle empty or undefined text
    
    return text.split('\n').map((line, i, arr) => (
      <React.Fragment key={i}>
        <Anchorme target="_blank">
          {line || ' '}
        </Anchorme>
        {i < arr.length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const renderContent = (): ReactNode => {
    if (message?.messageType === "reaction") {
      return formatContent(message.message.emoji || '');
    }

   if (["document", "image", "video", "audio"].includes(message?.messageType || '')) {
      const mediaMessage = {
        _id: message._id,
        messageType: message.messageType as 'video' | 'image' | 'document',
        downloadUrl: message.downloadUrl,
        message: {
          body: typeof message.message.body === 'string' ? message.message.body : '',
          id: message.message.id,
          mime_type: message.message.mime_type
        }
      };

      return (
        <MessageMedia 
          message={mediaMessage}
          onDownload={() => onMediaDownload(message)}
          integrationInfo={integrationInfo}
        />
      );
    }
// MessageBubble.tsx - Relevant part
// In MessageBubble.tsx - Update the footer render
const renderFooter = (message: any) => {
  if (!isLastInGroup) return null;
  
  return (
    <div className="message-footer">
      <span className="message-time">
        {moment(message.createdAt).format("HH:mm")}
      </span>
      
      {isOutgoing && (
        <div className="message-status">
          {message.messageDelivery === "sent" && (
            <i className="ri-check-line" />
          )}
          {message.messageDelivery === "delivered" && (
            <i className="ri-check-double-line" />
          )}
          {message.messageDelivery === "read" && (
            <i className="ri-check-double-line read" />
          )}
        </div>
      )}
    </div>
  );
};
    const messageText = typeof message.message.body === "string" 
      ? message.message.body 
      : message.message.body?.text || "";

    return (
      <div className="message-text-content">
        {formatContent(messageText)}
      </div>
    );
  };

  return (
    <div 
      className={`message ${isOutgoing ? 'outgoing' : 'incoming'}
        ${isFirstInGroup ? 'first-in-group' : ''} 
        ${isLastInGroup ? 'last-in-group' : ''}`}
    >
      <div className="message-container">
        {isFirstInGroup && isOutgoing && (
          <div className="message-sender">
            <span>{currentUser.firstName}</span>
          </div>
        )}
        
        <div className="message-content">
          {renderContent()}
          
          {isLastInGroup && showTimestamp && (
            <div className="message-footer">
              <span className="message-time">
                {moment(message.createdAt).format("HH:mm")}
              </span>
              
              {isOutgoing && (
                <div className="message-status">
                  {message.messageDelivery === "sent" && (
                    <i className="ri-check-line" />
                  )}
                  {message.messageDelivery === "delivered" && (
                    <i className="ri-check-double-line" />
                  )}
                  {message.messageDelivery === "read" && (
                    <i className="ri-check-double-line read" />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};